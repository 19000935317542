.timezoneSelect {
  & select {
    border: none;
    padding: 10px 20px 10px;
  }
}
.timezoneSelectDiv {
  border: 1px solid black;
  border-radius: 30px;
  position: relative;
}
.timezoneLabel {
  position: absolute;
  text-transform: capitalize;
  top: -10px;
  background: white;
  padding: 0 3px;
  color: var(--matterColorAnti);
  left: 15px;
}
