@import '../../styles/customMediaQueries.css';

.root{
  @media(max-width:767px){
    margin-top:40px;
  }
  @media(max-width:991px){
    margin-top:45px;
  }
}
.title {
  composes: h1 from global;

  font-size: 27px;
  /* text-transform: uppercase; */
  font-weight: 600;
  margin-bottom: 0;

  /* text-align: center; */
  margin-top: 0;

  color: var(--matterColorText);

  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;

  & > svg{
    width:17px;
    height:17px;
  }
  font-family:var(--marketplaceFontInter);

  @media(max-width:991px){
    /* font-size: 22px; */
    font-weight: 600;
    line-height: 26.63px;
  }

  @media (max-width: 767px) {
    /* font-size: 36px; */
    font-weight: 700;
    line-height: 43px;
    margin-bottom: 12px;
  }
  
  /* line-height: 41px; */
  /* @media (max-width: 767px) {
    margin-bottom: 50px;
  } */
}
.listingCard {
  margin: 0 0 26px 0;
  /* box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%); */
  /* padding: 10px; */
  background: #fff;
  border-radius: 8px;
  /* border: 1px solid #e8e8e8; */
  overflow: hidden;
  width: auto;
  min-height: 290px;
  transition: var(--transitionStyleButton);
  position: relative;
  transition: transform 0.2s ease-in-out;
  &:hover{
    transform: scale(1.02);
    box-shadow: 0px 4px 20px 0px var(--matterColorBoxShadow);
    /* padding: 10px; */
  }
}
.filteredSearches {
  display: flex;
  /* flex-direction: column; */
  margin-top: 0px;
  align-items: center;
  justify-content: space-between;

  /* @media (--viewportMedium) {
    
  } */
  /* @media (max-width: 767px) {
    padding: 0 0 50px;
  } */
  @media (max-width: 767px) {
    margin-top: 10px;
    overflow-x: scroll;
    scrollbar-width: none;
  -ms-overflow-style: none;
  }
}

.filteredSearches::-webkit-scrollbar {
  @media (max-width: 767px) {
    display: none;
  }
}

.searchLink {
  width: 90% !important;
  max-width: 320px;
  margin: 25px auto 0;
  object-fit: contain;
  /* Remove link's hover effect */
  /* box-shadow: var(--boxShadowSectionLocationHover); */
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  background-color: white;
  display: flex !important;
  justify-content: center;
  flex-direction: column;

  &:hover {
    text-decoration: none;
  }
  @media (max-width: 767px) {
    margin-top: 0 !important;
    padding: 0px !important;
    min-width: 200px !important;
    box-shadow:none !important;
    margin-right:20px !important;
  }
}

/* @media (--viewportMedium) {
  .searchLink {
    margin-top: 0;
    margin-right: 40px;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0 10px 0 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
} */

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  /* position: relative; */
  /* width: 100%; */
  background-color: var(--matterColorLight);

  border-radius: 4px;
  transition: var(--transitionStyleButton);

  /* padding-top: 60%; */

  /* &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  } */
}

/* An image wrapper that defines the images aspect ratio */

.searchImage {
  /* Layout - image will take space defined by aspect ratio wrapper */

  width: 100%;
  height: 175px;
  border-radius: 4px;
  object-fit: cover;
  /* padding: 10px; */
  margin-bottom: 15px;
}

.cardText {
  composes: h2 from global;
  margin: 10px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    margin: 10px;
  }
}
.topLine {
  display: flex;
  align-items: center;
  position: relative;
}
.item {
  font-size: 16px;
  color: var(--matterColorDark);
  font-weight: 300;
  margin-left: 5px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}
.itemPrice {
  color: var(--marketplaceColor);
  font-weight: 600;
  font-size: 20px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.circleDiv {
  width: 5px;
  height: 5px;
  margin: 0 20px 0 20px;
  background-color: #19825c;
  border-radius: 50%;
}

.heart {
  cursor: pointer;
  margin: 0 0 0 auto;
  fill: none;
  height: 20px;
  width: 17px;
  position: absolute;
  top: 6px;
  right: 0;
  &:hover {
    /* fill: #dd1212; */
    /* stroke: #dd1212; */
    & path {
      /* fill: #dd1212; */
      stroke: #dd1212;
    }
  }
  @media (max-width: 767px) {
    height: 14px;
    width: 14px;
  }
}
.itemContent {
  display: flex;
  flex-direction: column;
  color: var(--matterColorDark);
  font-size: 12px;
  @media (max-width: 575px) {
    word-break: break-all;
  }
}
.name {
  font-size: 18px;
  font-weight: 500;
  color: var(--matterColorText);
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 35px;
  }
  @media (max-width: 575px) {
    line-height: 23px;
    margin-bottom: 5px;
  }
}
.dName {
  font-size: 14px;
  font-weight: 300;
  color: var(--matterColorDarkWhite);
  margin-left: 10px;
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* @media (max-width:767px) {
    font-size: 14px;
  } */
}
.dNameDiv {
  display: flex;
  align-items: center;
}
/* .description {
  font-weight: 400;
} */
.profileIcon {
  height: 15px;
  width: 15px;
  border-radius: 50%;

  & path {
    fill: var(--matterColorDarkWhite);
  }
  /* @media (max-width:767px) {
    min-height: 15px;
    min-width: 15px;
  } */
}
@media (max-witdth: 767px) {
  .profileIcon {
    min-height: 15px;
    min-width: 15px;
  }
}
.browseBtnDiv {
  display: flex;
  justify-content: center;
  margin: 0 auto 35px;

  @media (max-width: 991px) {
    margin: 10px auto;
  }

  @media (max-width: 767px) {
    margin: 25px auto 35px;
  }
}
.browseBtn {
  width: 140px;
  border-radius: 24px !important;
}
.itemPriceUnit {
  font-size: 16px;
  color: var(--matterColorAnti);
  font-weight: 500;
  margin-left: 5px;
  text-transform: capitalize;
}
.noProfileIcon {
  height: 15px;
  width: 15px;
  & path {
    fill: var(--matterColorDarkWhite);
  }
  /* @media (max-width:767px) {
    min-height: 15px;
    min-width: 15px;
  } */
}
