@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
  @media (min-width: 320px) and (max-width: 575.98px) {
    padding: 11px 15px 0 15px;
  }
}

.form {
  flex-grow: 1;
}

/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* This is the title for the Edit Photos component */
.title {
  margin-bottom: 19px;
  font-size: 28px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0px;
  /* @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  } */
  @media (max-width: 767.98px) {
    margin-top: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 23px;
  }
}
.loginTitle {
  /* text-align: center; */
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  @media (max-width: 575.98px) {
    margin: 10px 0;
    text-align: center;
  }
}
.titleBlue,
.titleBlack {
  font-size: 25px;
  font-weight: 400;
}
.titleBlue {
  color: var(--marketplaceColorDark);
}
.titleBlack {
  color: var(--matterColorText);
}
.titleDiv {
  composes: titleDiv from global;
}
