@import '../../styles/customMediaQueries.css';
.root{
  @media(max-width:767px){
    margin-top:40px;
  }
}
.title {
  composes: h1 from global;

  font-size: 25px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 50px;

  text-align: center;
  margin-top: 0;

  color: var(--matterColorText);

  /* line-height: 41px; */
  @media (max-width: 767px) {
    margin: 30px 0 0;
  }
}

.filteredSearches {
  display: flex;
  /* flex-direction: column; */
  margin-top: 0px;
  align-items: center;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
  @media (max-width: 767px) {
    padding: 0 0 50px;
  }
}

.searchLink {
  width: 100%;
  margin-top: 25px;
  object-fit: contain;
  /* Remove link's hover effect */
  /* box-shadow: var(--boxShadowSectionLocationHover); */
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  background-color: white;

  &:hover {
    text-decoration: none;
  }
}

@media (--viewportMedium) {
  .searchLink {
    margin-top: 0;
    /* margin-right: 40px; */
    margin-bottom: 0;
    /* padding: 0 10px 0 10px; */
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.galleryItem{
  &>img{
      object-fit: cover;
    &:hover{
      cursor: pointer;
    }
  }
}
/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  /* position: relative; */
  /* width: 100%; */
  background-color: var(--matterColorLight);

  border-radius: 4px;
  transition: var(--transitionStyleButton);

  /* padding-top: 60%; */

  /* &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  } */
}

/* An image wrapper that defines the images aspect ratio */

.searchImage {
  /* Layout - image will take space defined by aspect ratio wrapper */

  width: 100%;
  height: 200px;
  border-radius: 4px;
  object-fit: cover;
  /* padding: 10px; */
  margin-bottom: 15px;
}

.cardText {
  composes: h2 from global;
  margin: 10px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    margin: 10px;
  }
}
.topLine {
  display: flex;
  align-items: center;
  position: relative;
}
.item {
  font-size: 16px;
  color: var(--matterColorDark);
  font-weight: 300;
  margin-left: 5px;
}
.itemPrice {
  color: var(--marketplaceColor);
  font-weight: 600;
  font-size: 20px;
}

.circleDiv {
  width: 5px;
  height: 5px;
  margin: 0 20px 0 20px;
  background-color: #19825c;
  border-radius: 50%;
}

.heart {
  cursor: pointer;
  margin: 0 0 0 auto;
  fill: none;
  height: 20px;
  width: 15px;
  position: absolute;
  top: 6px;
  right: 0;
  &:hover {
    /* fill: #dd1212; */
    /* stroke: #dd1212; */
    & path {
      /* fill: #dd1212; */
      stroke: #dd1212;
    }
  }
}
.itemContent {
  display: flex;
  flex-direction: column;
  color: var(--matterColorDark);
  font-size: 12px;
}
.name {
  font-size: 20px;
  font-weight: 500;
  color: var(--matterColorText);
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dName {
  font-size: 16px;
  font-weight: 300;
  color: var(--matterColorDarkWhite);
  margin-left: 10px;
}
.dNameDiv {
  display: flex;
  align-items: center;
}
/* .description {
  font-weight: 400;
} */
.profileIcon {
  min-height: 21px;
  min-width: 21px;
  & path {
    fill: var(--matterColorDarkWhite);
  }
}
.browseBtnDiv {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.browseBtn {
  width: 140px;
  border-radius: 24px !important;
}
.container {
  display: flex;
  flex-wrap: wrap;
  & > div{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
    align-items: flex-start;
    & >div:nth-child(1){
      width:34%;
      flex: 0 0 34%;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* align-items: center; */
    }
    & >div:nth-child(5){
      width:34%;
      flex: 0 0 34%;
      height: 300px;
    }
    & >div:nth-child(4){
      width:24%;
      flex: 0 0 24%;
      height: 300px;
    }
    & >div:nth-child(8){
      width:24%;
      flex: 0 0 24%;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 24px;
    }
    
    & >div:nth-child(2){
      width:23%;
      flex: 0 0 23%;
      height: 300px;
    }
    & >div:nth-child(6){
      width:23%;
      flex: 0 0 23%;
      height: 300px;
    }
    & >div:nth-child(3){
      width:14%;
      flex: 0 0 14%;
      height: 300px;
    }
    & >div:nth-child(7){
      width:14%;
      flex: 0 0 14%;
      height: 300px;
    }
    & > div{
      & > img{
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
  }
  @media (max-width:767px){
    & > div{
      gap:5px;
     padding: 0px !important;
      & > div{  
        width:48% !important;
        flex: 0 0 48% !important;
        height: 200px !important;
    }
    
    & >div:nth-child(1){
      display: none;
    }
    & >div:nth-child(8){
      display: none;
    }
  }
  }
  @media (max-width:1024px){
    & > div{
      justify-content: space-between;
      & > div{
        height: 200px !important;
        & > img{
          width: 100%;
          height: 100%;
        }
      }
      & >div:nth-child(1){
        width:32%;
        flex: 0 0 32%;
      }
      & >div:nth-child(5){
        width:32%;
        flex: 0 0 32%;
      }
      & >div:nth-child(4){
        width:25%;
        flex: 0 0 25%;
      }
      & >div:nth-child(8){
        width:25%;
        flex: 0 0 25%;
      }
      
      & >div:nth-child(2){
        width:21%;
        flex: 0 0 21%;
      }
      & >div:nth-child(6){
        width:21%;
        flex: 0 0 21%;
      }
      & >div:nth-child(3){
        width:14%;
        flex: 0 0 14%;
      }
      & >div:nth-child(7){
        width:14%;
        flex: 0 0 14%;
      }
    }
  }
  @media(min-width:1024px){
    &>div{
      gap:9px !important;
      &>div{
        height: 280px !important;
      }
    }
  }
}
/* .textContainer {
  flex: 1 1 200px;
  max-width: 300px;
} */
.subheading {
  font-size: 13px;
  font-weight: 400;
  color: var(--matterColorGrey);
  margin: 0;
  font-family: var(--marketplaceFontInter);
  @media(max-width:991px){
    font-size: 9.38px;
    font-weight: 400;
    line-height: 13.13px;
    letter-spacing: 0.09383215010166168px;
  }
}

.heading {
  font-size: 27px;
  font-weight: 600;
  color: var(--matterColorBlack);
  margin: 8px 0;
  font-family: var(--marketplaceFontInter);
  @media(max-width:991px){
    font-size: 22px;
    font-weight: 600;
    line-height: 26.63px;
  }
}
.link{
  font-size: 16px;
  font-weight:500;
  color:var(--matterColorBlack);
  cursor: pointer;
  font-family: var(--marketplaceFontInter);
  @media(max-width:991px){
    font-size: 11.55px;
    font-weight: 500;
    line-height: 16.17px;
    text-align: left;
    margin-top:5px;
  }
}
.lastBoxItem{
  font-size:16px;
  font-weight:400;
  color:var(--matterColorBlack);
  font-family:var(--marketplaceFontInter);
  @media(max-width:991px){
    font-size: 11.55px;
    font-weight: 400;
    line-height: 17.32px;
    letter-spacing: 0.11548571288585663px;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
  height: 142px;
  object-fit: cover;
  border-radius: 8px;
}
.mobileSubheading{
  @media(min-width:767px){
    display: none;
  }
  @media(max-width:767px){
    font-size: 13px;
    font-weight: 400;
    color: var(--matterColorGrey);
    margin: 0;
  }
}

.mobileHeading{
  @media(min-width:767px){
    display: none;
  }
  @media(max-width:767px){
  font-size: 27px;
  font-weight: 700;
  line-height: 43px;
  color: var(--matterColorBlack);
  margin-bottom: 15px;
  }
}