@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  composes: h4 from global;
  margin: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0 0;
  @media (min-width: 768px) and (max-width: 1023.98px) {
    font-size: 16px;
    padding-bottom: 12px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 14px;
  }
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  composes: h5 from global;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  composes: buttonFont from global;
  margin: 0px 0 10px;
}

.subTotalLineItem {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  composes: h4 from global;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-bottom: 2px;
  }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    font-size: 18px;
    font-weight: 600;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 16px;
    font-weight: 600;
  }
}

.smallFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
}

.itemLabel {
  /* composes: smallFontStyles; */
  flex: 1;
  margin-bottom: 4px;
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 14px;
  }
}

.itemValue {
  /* composes: smallFontStyles; */
  margin: 0 0 0 10px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 12px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 7px 0;
  }
}

.totalLabel {
  /* composes: smallFontStyles; */
  font-weight: 600;
  font-size: 16px;
}

.totalPrice {
  composes: buttonFont from global;
  margin: 0 0 0 10px;
  padding-top: 0px;
  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 600;
  }
}

.feeInfo {
  composes: marketplaceTinyFontStyles from global;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.linkItemLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
  font-weight: 400;
  border-bottom: 1px solid transparent;
  /* font-size: 14px; */
  line-height: 21px;
  padding-bottom: 2px;
  word-break: break-word;
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 14px;
  }
  & .icon {
    fill: transparent;
    width: 16px;
    height: 16px;
    margin-top: -2px;
    margin-left: 4px;
    flex-shrink: 0;
    & path {
      stroke: var(--marketplaceColor);
    }
  }

  &:active,
  &:hover,
  &:focus {
    text-decoration: none !important;
    border-bottom-color: var(--marketplaceColor);
  }
}

.shippingBtn {
  color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
  border-radius: 8px;
  &:hover,
  &:focus,
  &:active {
    border-color: var(--matterColorLight);
    color: var(--matterColorLight);
    background-color: var(--marketplaceColor);
  }
}
.cateHeading {
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    height: 1px !important;
    width: 100%;
    background-color: var(--matterColorNegative);
    top: 50%;
    transform: translate(16px, -50%);
  }
}

.actionBtnDiv {
  margin: 8px 0px 4px;
}
