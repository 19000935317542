@import '../../styles/customMediaQueries.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  /* padding: 24px 24px 42px 24px; */
  background-color: var(--matterColorBright);
  /* position: relative; */

  @media (--viewportMedium) {
    /* padding: 0 24px 33px 24px; */
  }

  @media (--viewportLarge) {
    /* padding: 0 0 81px 0; */
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  background-color: var(--matterColorText);
  /* color: var(--matterColorLight); */
  @media (--viewportMedium) {
    padding-top: 41px;
    padding-bottom: 20px;
  }

  @media (--viewportLarge) {
    /* padding-top: 73px;
    padding-bottom: 20px; */
    padding: 73px 20px 20px 20px;
    /* border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative); */
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1500px;

    /* max-width: 1128px; */
    /* padding: 0 36px; */
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
  @media (max-width: 1199px) {
    padding: 0 15px;
  }
  @media (max-width: 767px) {
    padding: 50px 0px 0;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 30px;
  margin-bottom: 20px;
  text-align: center;
  & svg {
    fill: none;
    stroke: white;
  }
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;
  white-space: nowrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
}

.serviceAbout {
  display: flex;
  width: 100%;

  & > .infoLinks:nth-child(2) {
    @media (max-width: 767.98px) {
      padding: 0 0 0 40px;
    }
    @media (max-width: 575px) {
      padding: 0;
    }
  }

  @media (max-width: 575px) {
    flex-direction: row;
    /* flex-direction: column; */
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
}

.logoLink {
  display: block;
  /* margin-top: 50px;
  margin-left: -20px; */
  @media (max-width: 767.98px) {
    text-align: center;
  }
}

.logo {
  width: 100%;
  height: 27px;
}

.organizationCopyright {
  composes: marketplaceTinyFontStyles from global;

  margin-top: 0px;
  text-align: center;
  margin-bottom: 0px;
  border-bottom: none;
  @media (max-width: 767px) {
    padding: 0 0 24px;
  }
}

.copyrightLink {
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
  @media (max-width: 991px) {
    font-size: 12px;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  /* background-color: red; */
  order: 2;
  flex-basis: 40%;
  margin-right: 24px;
  margin-bottom: 24px;
  color: var(--matterColorLight);

  @media (max-width: 991px) {
    flex-shrink: 0;
    margin: 0 15px 15px;
  }
  @media (max-width: 575px) {
    flex-basis: 85%;
  }
  /* @media(max-width:991px){
flex-basis: 33.33%;
  } */
}
.footerTitle {
  display: block;
  text-transform: uppercase;
  color: var(--matterColorLight);
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  margin: 10px 0;

  @media (max-width: 991px) {
    font-size: 16px;
  }
}
.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  composes: h5 from global;
  line-height: 20px;
  color: var(--matterColorLight);
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  /* color: var(--matterColor); */
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
  @media (max-width: 991px) {
    font-size: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  display: none;
  margin-top: 15px;
  margin-bottom: auto;
  margin-right: 24px;
  /* margin-bottom: 24px; */

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin: 15px;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    /* margin: 0 15px; */
  }
}

.sMediaIcon {
  fill: none;
  & path {
    stroke: white;
    stroke-width: 2px;
  }
  /* &:hover {
    fill: var(--marketplaceColor);
  } */
}
.linkdeinIcon {
  height: 25px;
  width: 25px;
}
.FooterLogoLink {
  padding-top: 0;
  margin-top: -20px;
  & img {
    @media (max-width: 991px) {
      margin-left: 0;
    }
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
}
.linksDiv {
  margin-left: 15px;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.someLinks {
  display: flex;
  flex-wrap: wrap;
}
