@import '../../styles/customMediaQueries.css';

.mobileInputRoot {
  @media (max-width: 767.98px) {
    flex-basis: 90%;
  }
  @media (max-width: 575.98px) {
    flex-basis: 88%;
  }
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
  @media (max-width: 991.98px) {
    display: flex;
    align-items: center;
  }
}
.keywordSearch {
  flex-basis: 70%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  position: relative;
  border-right: 1px solid #E7E7E7;
  @media(max-width:767px){
    flex-basis: 50%;
  }
}
.keywordSearchIcon {
  fill: #bdbdbd;
  margin: auto 5px;
  /* flex-basis: 20%;   */
  min-width: 20px;
  min-height: 20px;
}
.locationIcon {
  margin: auto 7px auto 5px;
  /* flex-basis: 20%;   */
  min-width: 20px;
  min-height: 20px;
  & path {
    fill: #bdbdbd;
  }
}

.keywordInput {
  flex-basis: 80%;

  & > input{
    /* font-family: Inter; */
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    border:none;
  }
}
.searchBtn {
  cursor: pointer;
  background-color: var(--marketplaceColor) !important;
  background-color: #d3e7ff;
  border: none;
  margin-left: 10px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 400;
  /* min-height: 45px; */
  /* min-width: 108px; */
  /* color: #fff; */
  padding: 2px 8px;
  color: #000000;
  position:absolute;
  right:5px;
  @media (max-width: 575px) {
    /* min-width: 120px;
    min-height: 40px; */
    padding: 5px 8px;
  }
}
.mobileIcon {
  @media (max-width: 767.98px) {
    flex-basis: 8%;
  }
  @media (max-width: 575.98px) {
    flex-basis: 10%;
  }

  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--marketplaceBGColor);
  & svg {
    fill: none;
  }
  & span {
    display: none;
  }
}
.searchBox {
  display: flex;
  /* width: 100%; */
  flex-basis: 30%;
  justify-content: center;
  align-items: center;
  /* margin: 0 10px; */
  @media (max-width: 767px) {
    flex-basis: 50%;
    /* justify-content: space-between; */
  }
}
.searchInputDiv {
  /* border: 2px solid var(--marketplaceColorBorder); */
  border: 2px solid var(--matterColorBlack);

  border-radius: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position:relative;
  @media (max-width: 991px) {
    flex-basis: 90%;
  }
  /* flex-basis: 90%; */
}
.hDivider {
  border-right: 1px solid var(--marketplacePlaceholder);
  margin: 5px 0px;
  flex-basis: 2%;
}
.mobileInput {
  /* flex-grow: 1; */
  /* flex-basis: 60%; */
  max-height: 42px;

  /* Font */
  /* composes: h1 from global; */

  /* Layout */
  margin: 0;
  /* padding: 4px 0 10px; */

  /* Borders */
  /* border-bottom-width: var(--borderRadiusMobileSearch); */

  border-bottom: none;
  padding: 10px 10px;

  /* font-size: 24px; */
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.5px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
  &::placeholder {
    color: var(--marketplacePlaceholder);
    font-weight: 400;
    font-size: 16px;
  }
}

.desktopIcon {
  /* flex-basis: %; */
  margin: 0 0 0 10px;
  border-radius: 10px;
  min-height: 42px;
  padding: 0 20px;
  font-size: 16px;
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);
  /* background-color: var(--marketplaceColorDark); */

  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    display: none;
  }
}

.desktopInput {
  composes: h4 from global;
  flex-grow: 1;
  /* Font */
  max-height: 42px;
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  /* padding-left: 10px; */

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.mobilePredictions {
  position: absolute;
  top: calc(
    var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder)
  );
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) +
          var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  /* min-width: 300px; */
  padding-bottom: var(--locationAutocompleteBottomPadding);
  /* background-color: var(--marketplaceColor); */
  background-color: var(--matterColorLight);

  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexTopbar) -1);
  /* @media (max-width: 767px) {
    left: -158px;
    min-width: 250px;
  } */
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  min-width: 300px;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 50px;
  left: 0;
  font-size: 12px;
  /* background-color: var(--marketplaceColor); */
  background-color: var(--matterColorLight);
  color: var(--matterColorDark) !important;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexTopbar) -1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );

  /* max-width: 434px; */
  @media (max-width: 767px) {
    left: 0;
    top: 40px;

    min-width: 250px;
  }
  @media (max-width: 575px) {
    position: fixed;

    left: 5%;
    right: 5%;
    top: 100px;
    width: 90%;
  }
}
