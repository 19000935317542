@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}
.textInput {
  flex-shrink: 0;
  margin: 16px 0px 16px;
  position: relative;
  & input {
    min-height: 54px !important;
  }

  & input,
  & textarea {
    border-bottom: 0px;
    border-radius: 15px;
    /* resize: auto; */
    resize: vertical !important;
    min-height: 77px;

    max-height: 350px;
    border: 1px solid var(--matterColorText);
    /* border: 1px solid var(--marketplaceColor); */
    padding: 20px 18px;
    box-sizing: border-box;
    /* border-radius: var(--borderRadius8); */
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColorBlack);
    @media (min-width: 320px) and (max-width: 575.98px) {
      border-radius: 15px;
      max-height: 146px;
      height: 77px;
      font-size: 14px;
    }
  }
  & label {
    font-weight: 600 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: #b5b5b5;
    font-size: 14px !important;
  }

  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
  & textarea {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.reviewRating {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 0;
  }
}

.reviewWrapper,
.disputeContentWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  & .ratingLabel {
    padding: 7px 12px;
    padding-left: 0px;
  }

  & .disputeLabel {
    margin: 0px;
    font-weight: 500;
    color: var(--matterColorAnti);
    font-size: 18px;
    line-height: 28px;
  }

  & .ratingSelect {
    flex-grow: 1;
    & select {
      max-width: 296px;
      padding: 6px 14px;
      padding-right: 32px;
      border-bottom: 0px !important;
      border-bottom: 0px;
      border: 1px solid black !important;
      border-radius: 30px !important;
      background-position: 96%;

      &:disabled {
        color: var(--matterColorDark);
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
}

.previewImage {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}

.disputeContentWrapper {
  margin-bottom: 20px;
}

.imgUploadWrapper {
  padding: 8px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & input {
    display: none;
  }
  & label {

    /* width: 168px; */
    /* height: 168px; */
    width: 130px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: dashed;
    border-color: var(--marketplaceColor);
    border-width: 2px;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;

    background-color: var(--matterColorLight);

    & svg {
      width: 20px;
      height: 20px;
      fill: transparent;
      margin-bottom: 8px;
      & path {
        stroke: var(--marketplaceColor);
      }
    }

    & .innerLabel {
      color: var(--marketplaceColor);
      display: flex;
      flex-direction: column;
      font-weight: 400;
      align-items: center;
      text-align: center;
      @media(max-width:575px){
        font-size:12px;
      }
    }
    @media(max-width:575px){
      width: 120px;
    height: 120px;
    margin-right:0;
    }
    @media(max-width:480px){
      width: 110px;
    height: 110px;
  
    }
    /* @media(max-width:420px){
      width: 100px;
      height: 100px;
 

    } */
    @media(max-width:393px){
      width: 95px;
      height: 95px;
 

    }
  }

  & .previewImageWrapper {

    margin-right: 8px;
    margin-bottom: 8px;
    position: relative;
    /* width: 168px; */
    /* height: 168px; */
    width: 130px;
    height: 130px;
    aspect-ratio: 1;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      background-color: #e9e8e8;
    }
    & .removeImage {
      position: absolute;
      top: 0;
      right: 0;
      width: 33px;
      height: 33px;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      border-radius: 0;
      border-bottom-left-radius: 2px;
      cursor: pointer;

      & svg {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 10px;
        height: 10px;
        fill: var(--matterColorAnti);
        stroke: var(--matterColorAnti);
      }

      &:hover svg {
        fill: var(--matterColorLight);
        stroke: var(--matterColorLight);
      }
    }
    @media(max-width:575px){
      width: 120px;
      height: 120px;
      margin-right:0;

    }
    @media(max-width:480px){
      width: 110px;
      height: 110px;
 

    }
    /* @media(max-width:420px){
      width: 100px;
      height: 100px;
    } */
    @media(max-width:393px){
      width: 95px;
      height: 95px;
    }
  }
  @media(max-width:575px){
    gap: 2px;
  }
}
