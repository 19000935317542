@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.submitButton {
  margin-top: auto;
  flex-shrink: 0;
  flex-basis: 30%;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}
.categoryReactSelect {
  width: 100%;
  /* margin-bottom: 20px; */
  border-color: var(--matterColorDark) !important;
  /* display: flex; */
  margin: 20px 0;
  & > div:nth-child(3) {
    /* border-color: var(--matterColorDark) !important;
    border-width: 0px !important;*/
    border-radius: 30px !important;
    border: none;
    box-shadow: none !important;
    height: 42px;
    & > div:nth-child(1) {
      overflow: visible;
    }

    & > div:last-child {
      & > span:first-child {
        display: none;
      }
    }
  }
}
.reactSelect {
  width: 48%;
  margin-bottom: 20px;
  border-color: var(--matterColorDark) !important;
  /* display: flex; */

  & > div:nth-child(3) {
    border-color: var(--matterColorDark) !important;
    border-width: 0px !important;
    /* border-radius: 0px !important; */
    box-shadow: none !important;
    height: 57px;

    & > div:last-child {
      & > span:first-child {
        display: none;
      }
    }
  }
}
.cateSelect {
  border: 1px solid black;
  border-radius: 30px;
  padding-left: 10px;
  & .valueContainer {
    & .value {
      & > div:first-child {
        margin-left: 0px !important;
      }
    }
  }
}

.inputs {
  margin-top: 8px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 8px;
  flex-basis: 49%;
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;

    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    left: 1.2rem !important;
    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.dropDownIndicatior {
  color: var(--matterColorDark);
  font-size: 22px;
  cursor: pointer;
  position: relative;
  /* z-index: 21; */
  fill: none;
  margin: 0 20px 0 0;
  @media (min-width: 320px) and (max-width: 575.98px) {
    margin: 0 15px 0 0;
  }
}
.disabledIndicator {
  color: var(--wardrobeColorBrown);
}
.menu {
  border-radius: 0px !important;
  margin-top: 1px !important;
  width: calc(100% + 2px) !important;
  left: -1px !important;

  z-index: 20 !important;
  /* margin-bottom: 50px; */

  & > div:first-child {
    border-top-width: 0px;
    background-color: #fff;
    overflow-y: auto;
    scrollbar-width: default;
    scrollbar-color: #e1e1e1 transparent;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--matterColorDimGrey);
      border: 2px solid #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--wardrobeColorDimGrey);
      border-width: 0px;
      border-radius: 10px;
    }

    & > div {
      font-weight: 400;
      border-bottom: 1px solid transparent;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      height: 61px;
      font-size: 16px;
      /* Dimensions */
      width: 100%;
      margin: 0;
      padding: 4px 24px;
      /* Layout details */
      color: var(--matterColorText);
      text-align: left;
      transition: var(--transitionStyleButton);

      /* &:hover {
        background-color: var(--marketplaceColorLight);
      } */
      &:hover,
      &:active,
      &:focus {
        color: var(--matterColorText) !important;
        background-color: var(--marketplaceColorLight);
      }

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 24px);
        height: 1px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--matterColorPaleWhite);
      }
    }

    & > div:first-child {
      color: var(--wardrobeColorBrown) !important;
      background-color: var(--matterColorLight) !important;

      &:hover {
        background-color: var(--marketplaceColorLight) !important;
      }
    }

    & > div:last-child,
    & > div:first-child {
      &::after {
        background-color: transparent;
      }
    }
  }
}
.multiValueContainer {
  /* height:  !important; */
  height: auto !important;
  min-height: 42px !important;

  & .emptyPlaceholder {
    top: 10px !important;
    left: 0px !important;
  }

  & .selectedPlaceholder {
    top: -3px !important;
    left: calc(-10% + 2px) !important;
  }
  & > span:first-child {
    position: relative;
    display: block;
    left: -16px;
    line-height: 20px;
    top: -2px;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    top: -2px;
    & > div:not(:last-child) {
      margin: 0px 3px;
      display: inline-block;
      position: relative;
      top: 0px !important;

      & > div:first-child {
        font-size: 14px;
        line-height: 17px;
        padding: 0px;
        background-color: #fff;
        overflow: unset !important;
      }
      & > div:last-child {
        display: none;
      }
    }
    & > div {
      position: relative;
      &::after {
        content: ',';
        position: absolute;
        right: -4px;
        bottom: -6px;
      }
    }

    & > div:nth-last-child(2) {
      &::after {
        display: none;
      }
    }
    & > div:nth-child(n + 4) {
      display: none;
    }
    & > div:nth-child(3) {
      &::after {
        content: '...';
        right: -16px;
        font-weight: 500;
        font-size: 18px;
      }
    }
    & > input:last-child {
      margin-left: 10px;
      position: absolute;
    }
  }
}
.valueContainer {
  /* height: 40px;
  padding: 2px; */
  position: relative;
  height: 42px;
  width: 100%;
  cursor: pointer;
  padding-left: 0.1rem;
  & .placeholder {
    /* color: var(--wardrobeColorDarkGray); */
    color: #b5b5b5;
    font-weight: var(--fontWeightSemiBold);
    /* font-weight: 400; */
    font-size: 14px;
    position: absolute;
    top: 2px;
    left: 0px;
    /* padding-left: 0.4rem; */
    z-index: 2;

    @media (max-width: 767px) {
      top: 8px;
    }
  }

  & .selectedPlaceholder {
    transform: scale(0.9);
    top: -20px;
    left: -3px;
    background-color: #ffffff;
    padding: 0 2px;
  }
  & .selectedSCPlaceholder {
    transform: scale(0.8);
    top: -6px;
    left: -15px;
  }
  & .value {
    & > div:first-child {
      position: relative;
      top: 3px;
      font-size: 16px;
      color: var(--matterColorText);
      text-transform: uppercase;
    }

    & > div:last-child {
      position: absolute;
      z-index: 9;
      width: 100%;
      left: -2px;
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
      top: -5px;
      left: 0px;
    }
  }

  & .blurredValue {
    & > div:first-child {
      color: var(--wardrobeColorDarkGray);
    }
  }
}

.lowerDiv {
  display: flex;
  align-items: center;
  margin: 50px 0;

  /* flex-direction: column; */
  @media (min-width: 320px) and (max-width: 575.98px) {
    display: flex;
    position: fixed;
    width: 100%;
    margin: 20px 0 0;
    bottom: 0;
    left: 0;
    background: white;
    padding: 16px;
    box-shadow: 0px -2px 7px rgb(0 0 0 / 25%);
    flex-direction: row-reverse;
    z-index: 9;
  }
}
