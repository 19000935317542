@import '../../styles/customMediaQueries.css';

.fieldRoot {
}

.pickerSuccess {
  & :global(.DateInput_input) {
    /* border-bottom-color: var(--successColor); */
    border-color: #000000;
  }
}

.pickerError {
  & :global(.DateInput_input) {
    border-color: var(--failColorLight);
  }
}

.labelDisabled {
  color: var(--matterColorNegative);
  top: -8px !important;
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  /* width: calc(100% - 48px); */
  /* margin: 0 24px; */
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: white;
  position: absolute;
  top: -2px;
  left: 20px;
  z-index: 9;
  padding: 0 3px;
  &::after {
    content: '*';
    color: red;
    margin-left: 3px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    top: -4px;
    margin: 0 18px;
  }
}
