.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--borderRadius);
  }
  .thumbnail {
    /* display: block; */
    /* float: left;
    position: relative; */
    /* width: 100%; */
    margin: 0;
    /* overflow: hidden; */
    background-color: var(--matterColorNegative); /* Loading BG color */
    /* width:calc(50% - 12px); */
    width: 406px;
    margin: 0 12px 12px 0;
    /* @media(max-width:1440px){
        width: 450px;
    }
    @media(max-width:576px){
        width:100%;
    } */
    @media(max-width:992px){
        width:348px;
      }
      @media(max-width:768px){
        width:248px;
      }
      @media(max-width:575px){
        width:325px;
        margin-right: 0px;
      }
  }
  .removeImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    cursor: pointer;
  
    & svg {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 10px;
      height: 10px;
      fill: var(--matterColorAnti);
      stroke: var(--matterColorAnti);
    }
  
    &:hover svg {
      fill: var(--matterColorLight);
      stroke: var(--matterColorLight);
    }
  }
  .aspectWrapper {
    padding-bottom: calc(100% * (2 / 3));
  }
  .threeToTwoWrapper {
    position: relative;
    padding-bottom: calc(100% * (2 / 3));
    /* width:calc(50% - 12px);
    height: calc(50% - 12px); */
    /* width:400px; */
  }
  .thumbnailLoading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(211, 211, 211, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }