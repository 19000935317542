@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  cursor: pointer;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  height: 199px;
  &:hover {
    /* transform: scale(1.02); */
    /* box-shadow: var(--boxShadowListingCard); */
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  /* background: var(--matterColorNegative); Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
  height: 199px;
  object-fit: cover;
  border-radius: 8px;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px 0 2px 0;
  /* margin-bottom: 5px; */
  padding-left:10px;
  @media(max-width:767px) {
    margin-bottom: 0px !important;
  }
}

.price {
  /* Layout */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
  align-items: center;

}

.priceValue {
  /* Font */
  composes: h2 from global;
  font-size: 16px;
  font-weight: 600;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-family: var(--marketplaceFontInter);

  @media (max-width: 767px) {
    font-size: 11px;
    font-weight: 600;
  }
  @media (max-width: 575.98px) {
    font-size: 12px;
  }
  @media(max-width:991px){
    font-size: 11.55px;
    font-weight: 600;
    line-height: 14.44px;
    letter-spacing: 0.057742856442928314px;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  font-size: 14px;
  font-weight: 400;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color:var(--matterColorGrey);
  font-family: var(--marketplaceFontInter);
  @media (max-width: 767px) {
    font-size: 10px;
    font-weight: 400;
  }
  @media (max-width: 575.98px) {
    font-size: 12px;
  }
  @media(max-width:991px){
    font-size: 10.11px;
    font-weight: 400;
    line-height: 14.44px;
    letter-spacing: 0.057742856442928314px;
  }
}
.addToFavDiv {
  margin-left: auto;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    fill: none;
    @media (max-width: 575.98px) {
      width: 18px;
      height: 18px;
    }
  }
  & button {
    border: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
}
.mainInfo {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: var(--matterColorText);
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  max-width: 90%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
  min-height:40px;
  /* text-overflow: ellipsis;
  white-space: nowrap; */
  font-family: var(--marketplaceFontInter);
  @media (max-width: 767px) {
    font-size: 11px;
    font-weight:500;
    line-height:14px;
  }
  @media (max-width: 575.98px) {
    font-size: 12px;
  }
  @media(max-width:991px){
    font-size: 11.55px;
    font-weight: 500;
    line-height: 14.44px;
    letter-spacing: 0.057742856442928314px;
  }
}
.listingAuthor {
  display: flex;
  align-items: center;
  /* margin: 10px 0; */
  color: var(--matterColorDarkWhite);
  text-transform: capitalize;
  padding-left: 10px;
  /* margin: auto 0 0px; */
  @media(min-width:767px){
    margin-top: -10px;
  }

  & > img{
    width: 17px;
    height: 17px;
  }
  @media (max-width: 767px){
    & > img{
      width: 12px;
      height: 12px;
    }
  }
  & svg {
    margin-right: 10px;

    & path {
      fill: var(--matterColorDarkWhite);
    }
    @media (max-width: 575.98px) {
      width: 18px;
      height: 18px;
    }
  }
  & span {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    font-weight:500;
    color:#6A6A6A;
    font-family: var(--marketplaceFontInter);
    @media (max-width: 767px) {
      font-size: 9px;
    }
    @media(max-width:991px){
      font-size: 9.38px;
      font-weight: 500;
      line-height: 14.44px;
      letter-spacing: 0.057742856442928314px;
    }
  }
}
.certificateInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.favBtn {
  fill: none;
  cursor: pointer;
}
.unfavBtn {
  cursor: pointer;

  fill: var(--marketplaceColor) !important;

  & path {
    stroke: var(--marketplaceColor);
  }
}
.favBtn,
.unfavBtn {
  margin-left: auto;
}
.profileIcon {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 5px;

  & path {
    fill: var(--matterColorDarkWhite);
  }
  @media (min-width: 320px) and(max-width: 767.98px) {
    min-height: 15px;
    min-width: 5px;
  }
}

.noProfileIcon {
  height: 20px !important;
  width: 20px !important;
  & path {
    fill: var(--matterColorDarkWhite);
  }
  /* @media (max-width:767px) {
    min-height: 15px;
    min-width: 15px;
  } */
}
.strikedOutPrice {
  text-decoration: line-through;
  text-transform: uppercase;
  /* margin-left: 0; */
  /* margin-top: -10px; */
  font-size: 13px;
  font-size: 400;
  color: #0F743B;
  margin-left: 5px;
}
.ratingDiv {
  /* margin: 00px 0 20px 0; */
  & svg {
    width: 14px;
    height: 14px;
    margin-right: 3px;
    & path{
      fill:var(--matterColorBlack);
    }
  }
}
.sectionReviews{
  margin-bottom:0px !important;
}
.hoverClass{
  display:none;
}