@import '../../styles/customMediaQueries.css';

.unsupportedCountryError {
  color: var(--failColor);
}

.root {
  /* Parent component should not shrink this field */
  flex-shrink: 0;

  /* This component uses flexbox layout too */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inputDiv {
  /* Parent component should not shrink this field */
  flex-shrink: 0;
  /* border-bottom-color: var(--attentionColor); */
  margin-bottom: 15px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid var(--matterColorText);
  & label {
    font-size: 16px;
    font-weight: 400;
  }
  & input {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 5px 20px;
  }
}

.longForm {
  width: 100%;
  margin-bottom: 24px;
}

@media (--viewportSmall) {
  .longForm {
    width: calc(50% - 9px);
  }
}

.inputSuccess {
  /* border-bottom-color: var(--successColor); */
  /* border-color: var(--marketplaceColor); */
}

.inputError {
  /* border-bottom-color: var(--failColor); */
  border-color: var(--failColor);
}

.error {
  margin-top: 5px;
  color: var(--failColor);
}
