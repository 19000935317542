@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;

  @media (min-width: 320px) and (max-width: 575.98px) {
    padding-bottom: 100px;
  }
}

.imagesField {
  /* flex-shrink: 0;
  margin: 0; */
  display: flex;
    flex-wrap: wrap;
    width:100%;
    @media(max-width:575px){
      justify-content: center;
    }
}
.svgImg {
  fill: none;
}
.thumbnail {
  /* margin: 12px 0 0 0; */
  /* width:100%; */
  flex:0 0 50%;
  position:relative;

  @media (--viewportLarge) {
    /* width: calc(50% - 12px); */
    margin: 0 12px 12px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  /* width: 100%; */
  width:406px;
  /* margin: 24px 0 0 0; */
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
  @media(max-width:992px){
    width:348px;
    height:100%;
  }
  @media(max-width:768px){
    width:248px;
  }
  @media(max-width:575px){
    width:325px;
  }

  @media (--viewportLarge) {
    /* width: calc(50% - 12px); */
    /* width:455px; */
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorText);
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 575.98px) {
    text-align: center;
    font-size: 14px;
    margin: 0 0 10px 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  flex-shrink: 0;
  flex-basis: 30%;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}
.hDivider {
  border-bottom: 1px solid var(--matterColorDivider);
  margin-top: 10px;
  @media (max-width: 575.98px) {
    display: none;
  }
}
.cancelButton {
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
  flex-basis: 30%;
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}
.lowerDiv {
  display: flex;
  align-items: center;
  margin: 50px 0;

  /* flex-direction: column; */
  @media (min-width: 320px) and (max-width: 575.98px) {
    display: flex;
    position: fixed;
    width: 100%;
    margin: 20px 0 0;
    bottom: 0;
    left: 0;
    background: white;
    padding: 16px;
    box-shadow: 0px -2px 7px rgb(0 0 0 / 25%);
    flex-direction: row-reverse;
    z-index: 9;
  }
}
