@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

/* .firstNameRoot {
  width: calc(34% - 9px);
} */
.emailRoot {
  width: 100%;
  flex-basis: 49%;
  position: relative;
  & label {
    position: absolute;
    left: 1.4rem;
    top: -14px;
    pointer-events: none;
    color: #b5b5b5;
    background-color: #ffffff;

    padding-right: 2px;
    padding-left: 2px;
  }
  & input {
    margin: 8px 0;
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;
    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.nameRoot {
  width: 100%;
  flex-basis: 49%;
  position: relative;
  & label {
    position: absolute;
    left: 1.4rem;
    top: -6px;
    pointer-events: none;
    color: #b5b5b5;
    background-color: #ffffff;

    padding-right: 2px;
    padding-left: 2px;
  }
  & input {
    margin: 8px 0;
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;
    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  margin-top: 36px;
  margin-bottom: 46px;
}

.bottomWrapperText {
  /* composes: marketplaceModalBottomWrapperText from global; */
}

.termsText {
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: var(--matterColorText);
}

.termsLink {
  /* composes: marketplaceModalHelperLink from global; */
  /* margin: 0 0px 30px 10px; */
  color: var(--marketplaceColor);
  font-weight: 500;
  &:hover {
    color: var(--marketplaceColorDark);

    cursor: pointer;
  }
}

.cnfmBtn {
  width: 100%;
  max-width: 254px;
  margin: 0 auto;
  padding: 15px;
}
.titleBlue {
  color: var(--marketplaceColorDark);
}
