@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-height: 49px;
  /* Font */
  font-weight: 500;

  /* font-weight: var(--fontWeightRegular); */
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0;
  border-bottom:2px solid transparent;
  color: var(--matterColorText);

  padding: 10px 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  /* border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent; */

  /* transition: var(--transitionStyleButton); */
  /* SelectedLink's width (200px) + border thickness (4px) = 204px */
  /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 4px) center;  */

  &:hover {
    text-decoration: none;
    /* color: var(--matterColorLight); */
    /* font-weight: 500; */
    /* background-color: var(--marketplaceColor); */
    /* border-radius: 5px; */
  }

  @media (--viewportLarge) {
    /* font-weight: var(--fontWeightSemiBold); */
    font-size: 20px;
    line-height: 24px;

    /* width: var(--TabNav_linkWidth); */
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 5px;

    /* padding-top: 6px; Drop the text to adjust it to correct baseline */
    /* padding-bottom: 2px; */

    /* &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
      background-position: right center;
    } */
  }

  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 16px;
  }
}

.selectedLink {
  border-bottom-color: var(--matterColorDark);
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);
  border-radius: 5px;

  @media (max-width: 1023px) {
    background-color: transparent !important;
    border-radius: 0;
    border-bottom: 2px solid var(--marketplaceColor);
    color: var(--marketplaceColor);
  }
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}
