@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}
.dmDiv {
  display: flex;
  flex-direction: column;
}
.titleDiv {
  display: flex;
  flex-direction: column;
}

.divTitle {
  font-size: 25px;
  color: var(--matterColorText);
  font-weight: 400;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 3px 0 3px 0;
  @media (max-width: 575.98px) {
    font-size: 20px;
  }
}
.divLabel {
  flex-shrink: 0;
  color: var(--matterColorText);
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 575.98px) {
    text-align: left;

    font-size: 14px;
    margin: 0 0 10px 0;
  }
}
.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.submitButton {
  margin-top: auto;
  flex-shrink: 0;
  flex-basis: 30%;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}
.termLabel {
  display: flex;
  cursor: pointer;
}
.checkBox {
  flex-shrink: 0;
  fill: none;
}
.inputBox {
  display: none;
}

.boxDiv {
  composes: marketplaceModalHelperText from global;
  /* margin-bottom: 20px; */
  text-align: left;
  display: flex;
  color: var(--matterColorText);

  @media (min-width: 320px) and (max-width: 575.98px) {
    margin-top: 10px;
  }
  & label {
    font-weight: 400;
    margin-bottom: 10px;
    margin-right: 20px;
    & svg {
      margin-right: 10px;
      fill: none;
      width: 16px;
      height: 16px;
    }
  }
  /* & input {
    width: auto !important;
    margin-right: 10px;
  }  */
  & span {
    font-size: 14px;
    font-weight: 600;
    & label {
      display: none;
    }
    & a {
      & span {
        font-weight: 400;
        font-size: 14px;
        color: var(--matterColorText);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.inputs {
  margin-top: 8px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 8px;
  flex-basis: 49%;
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;

    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    left: 1.2rem !important;
    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.inputLabel {
  & label {
    transform: translateY(-49%) scale(0.97) !important;
    left: 0.8rem !important;
  }
}
.chargeDiv {
  @media (min-width: 320px) and (max-width: 575.98px) {
    margin-top: 10px;
  }
}
.locationWrapper {
  margin-top: 24px;
  margin-bottom: 20px;
}
.locationDiv {
  border-radius: 30px;
  border: 1px solid var(--matterColorText);
  position: relative;
  height: 100%;
  min-height: 347px;
  display: flex;
  flex-direction: column;

  position: relative;
}
.locationText {
  padding: 10px 10px;
  & label {
    font-weight: 600 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 18px;
    padding: 0px 4px;
    color: #b5b5b5;
    font-size: 14px !important;
  }
  & input {
    border: 1px solid var(--matterColorText);
    border-radius: 30px;

    padding: 10px 20px;
  }
}
.loaderDiv {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #00000061;
  display: none;
  flex-direction: column;
  z-index: 5;
  border-radius: 24px;

  & .loader {
    width: 48px;
    height: 48px;
    margin: auto;
    stroke: #fff;
  }
}
.showLoaderDiv {
  display: flex;
}
.map {
  width: 100% !important;
  height: 100% !important;
  padding: 0 10px 10px;
}
.cancelButton {
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
  flex-basis: 30%;
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}
.lowerDiv {
  display: flex;
  align-items: center;
  margin: 50px 0;

  /* flex-direction: column; */
  @media (min-width: 320px) and (max-width: 575.98px) {
    display: flex;
    position: fixed;
    width: 100%;
    margin: 20px 0 0;
    bottom: 0;
    left: 0;
    background: white;
    padding: 16px;
    box-shadow: 0px -2px 7px rgb(0 0 0 / 25%);
    flex-direction: row-reverse;
    z-index: 9;
  }
}
