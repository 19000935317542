.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}
.divTitle {
  display: block;
  font-size: 25px !important;
  font-weight: 400 !important;
  margin: 20px 0 0 0;
}
.divLabel {
  display: block;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 0px 0 20px 0;
}
.inputs {
  margin-top: 8px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 8px;
  flex-basis: 49%;
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;

    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    left: 1.2rem !important;
    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.hDivider {
  border-bottom: 1px solid var(--matterColorDivider);
  margin-top: 10px;
}
.tip {
  composes: h5 from global;
  margin-top: 0;
  flex-shrink: 0;
  color: var(--matterColorText);
  margin-left: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.lowerDiv {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.submitButton {
  margin-top: auto;
  /* margin-bottom: 24px; */
  flex-shrink: 0;
  max-width: 241px;

  @media (--viewportLarge) {
    display: inline-block;
    max-width: 241px;
    margin-top: 100px;
  }
}
.cancelButton {
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
}
