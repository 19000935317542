@import '../../styles/customMediaQueries.css';

.title {
  font-size: 27px;
  /* text-transform: uppercase; */
  font-weight: 600;
  margin-bottom: 25px;
  /* display: block; */
  color:var(--matterColorBlack);
  font-family:var(--marketplaceFontInter);
  /* text-align: center; */
  @media(max-width:991px){
    /* font-size: 22px; */
    font-weight: 600;
    line-height: 26.63px;
  }
  @media(max-width:767px) {
    /* font-size: 36px; */
    line-height: 26.63px;
  }
  @media(max-width:767px) {
    /* font-size: 36px; */
    font-weight: 700;
    /* max-width: 150px; */
    margin-bottom: 10px;
    line-height: 40px;
  }
}

.locations {
  display: flex;
  margin-top: 0px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  
  @media (max-width: 767px) {
    overflow-x: scroll !important;
    flex-wrap: nowrap !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }
}

.locations::-webkit-scrollbar {
  @media (max-width: 767px){
    display: none !important;
  }
}

.catbtn {
  /* flex-basis: 17%; */

  border: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  flex-wrap: wrap;
  flex: 0 0 16%;
  width: 16%;
  & span {
    margin: 0 auto;
  }
  @media(max-width:767px){
    flex: 0 0 auto !important;
    width: 169px !important;
    height: 267px !important;
  }
  /* @media (min-width: 576px) and (max-width: 1199px) {
    flex-basis: 48%;
  } */

  /* @media (max-width: 575px) {
    flex-basis: 48%;
  } */
  /* @media (min-width: 321px) and (max-width: 575.98px) {
    flex-basis: 33%;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    flex-basis: 50%;
  } */
  /* @media (min-width: 768px) and (max-width: 1199px) {
    flex-basis: 50%;
  } */
}
/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  /* padding-bottom: calc(6 / 13 * 100%); */
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}
.catimg {
  object-fit: cover;
  border-radius: 50%;
  height: 120px;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
  object-fit: cover;
  @media (max-width: 991px) {
    width: 81px;
    height: 81px;
    width: 100%;
  }
}

.catimg2 {
  object-fit: cover;
  border-radius: 12px;
  height: 252px;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
  transition: transform 0.2s ease-in-out;

  @media (max-width: 767px) {
    max-width: 176px;
    object-fit: cover;
    margin:0px !important;
  }
  @media(max-width:991px){
    height: 160px !important;
  }
  @media(max-width:1024px){
    height: 192px;
  }
  @media(max-width:1024px){
    /* height: 120px; */
  }
  /* @media (max-width: 1200px) {
    width: 100%;
    min-height: 350px;
  }
  @media (max-width: 1199px) {
    width: 100%;
    
  } */
   
 &:hover{
   transform: scale(1.05);
   border-radius: 10px;
 }
}


.linkText {
  color: var(--matterColorText);
  margin: 0 !important;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0 20px;
  font-family: var(--marketplaceFontInter);
  @media(max-width:991px){
    font-size: 12.99px;
    font-weight: 600;
    line-height: 14.44px;
  }
  @media (max-width: 768px) {
    line-height: 32px;
  }
  &:hover{
    text-decoration: underline;
  }
}

.browseBtnDiv {
  display: flex;
  justify-content: center;
  margin: 25px auto;
}
.browseBtn {
  width: 140px;
  border-radius: 24px;
}
.locationWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
    @media (max-width: 767px) {

      margin-top: 12px;
    }
}
