.root {
}

.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    /* border-bottom-color: var(--matterColorNegative); */
    border-color: var(--matterColorNegative);

    color: var(--matterColorNegative);
    cursor: default;
  }
}
.timeLabel {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: white;
  position: absolute;
  top: -8px;
  left: 20px;
  z-index: 9;
  padding: 0 3px;

  &::after {
    content: '*';
    color: red;
    margin-left: 3px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    top: -10px;
  }
}
.selectSuccess {
  color: var(--matterColor);
  /* border-bottom-color: var(--successColor); */
  border-color: #000000;
}

.selectError {
  /* border-bottom-color: var(--failColor); */
  border-color: var(--failColorLight);
}
