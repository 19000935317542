.root {
  /* width: 100%; */
  /* min-height: 300px; */
  /* background-color: #fff; */
  /* padding: 20px; */
  /* border: 1px solid; */
  margin: 0 0 26px 0;
  /* box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%); */
  padding: 15px 10px;
  background-color: #fff;
  border-radius: 21px;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  /* width: auto; */
  min-height: unset;
  /* min-width: 278px; */

  /* @media (min-width: 776px) {
    min-height: 475px;
  } */
}
.profileIcon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;

  & path {
    fill: var(--matterColorDarkWhite);
  }
  @media (min-width: 320px) and(max-width: 767.98px) {
    min-height: 15px;
    min-width: 5px;
  }
}

.noProfileIcon {
  height: 30px !important;
  width: 30px !important;
  & path {
    fill: var(--matterColorDarkWhite);
  }
  @media (max-width: 767px) {
    min-height: 15px;
    min-width: 15px;
  }
}
.imgNameWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 15px;
  padding: 15px 0;
  /* border-bottom: 1px solid #e8e8e8; */
}
.name {
  text-transform: capitalize;
  /* color: var(--matterColorDarkWhite); */
  font-weight:600;
  font-size:18px;
  font-family: var(--marketplaceFontInter);
  @media(max-width:991px){
    font-size: 12.99px;
    font-weight: 600;
    line-height: 25.71px;
    letter-spacing: 0.16713851690292358px;
  }
}
.review {
  /* margin: 10px 10px; */
  /* width: 100%; */
  flex: 1;
  display: flex;
  flex-direction: column;
  /**/
  overflow: hidden; /* Ensures content doesn't overflow the card */
  position: relative; /* Allows absolute positioning of buttons */
  /* border: 1px solid #ddd; */
  padding: 0 15px 24px;
  box-sizing: border-box;
  color: var(--matterColorDarkWhite);
  @media (min-width: 776px) {
    padding: 0 15px;
  }
}

.lineReview {
  @media (min-width: 576px) {
    /* min-height: 340px; */
    height: 235px !important;
  }
  @media (min-width: 650px) and (max-width: 767px) {
    /* min-height: 340px; */
    height: 244px;
  }
  @media (min-width: 944px) and (max-width: 1026px) {
    /* min-height: 340px; */
    height: 244px;
  }
  @media (min-width: 1027px) and (max-width: 1091px) {
    /* min-height: 340px; */
    height: 360px;
  }
  @media (min-width: 1092px) and (max-width: 1199px) {
    /* min-height: 340px; */
    height: 320px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    /* min-height: 340px; */
    height: 300px;
  }
  @media (min-width: 1400px) {
    /* min-height: 340px; */
    height: 280px;
  }
  @media (min-width: 1921px) {
    /* min-height: 340px; */
    height: 380px;
  }
  /* min-height: 340px; */

  /* @media (min-width: 776px) { */
  /* min-height: 340px; */
  /* height: 300px;
  } */
}

.content {
  flex-grow: 1;
  overflow: hidden; /* Hide overflowed content */
  display: flex;
  flex-direction: column;
  & > p{
    font-size:16px;
    font-weight:400;
    line-height: 24px;
    color:var(--matterColorBlack) !important;
    font-family: var(--marketplaceFontInter);
    @media(max-width:991px){
      font-size: 11.55px;
      font-weight: 400;
      line-height: 17.32px;
      letter-spacing: 0.16713851690292358px;
    }
  }
}
.readbtn {
  align-self: flex-start; /* Align button to the start */
  margin-top: 12px;
  background: none;
  border: none;
  color: var(--matterColorBlack);
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-weight:400;
  font-size:14px;
  font-family: var(--marketplaceFontInter);
  @media(max-width:991px){
    & > span{
      font-size: 10.11px;
      font-weight: 400;
      line-height: 16.17px;
      text-align: left;
    }
  }
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
.modalContent {
}

.modalReview {
  @media (max-width: 775px) {
    margin-top: 36px;
  }
}
