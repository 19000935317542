.customUserNav {
  background-color: var(--matterColorLight) !important;
  padding: 0 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #0E0E0E2E;

  @media (max-width: 767px) {
    height: auto;
    flex-direction: column;
  }
}
.customUserNav button {
  border: 0;
  padding: 18px 25px;
  color: var(--matterColorBlack);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 18px;
  @media (max-width: 1199px) {
    font-size: 14px;
    padding: 18px 12px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.arrow {
  fill: none;
  width: 10px;
  height: 5px;
  margin-left: 10px;
  font-weight: 700;
  & path {
    stroke: #ffffff;
  }
}
.navBar {
  padding: 0;
}
.noOption + div {
  display: none;
}
.optionDiv + div {
  & ul {
    border-radius: 5px;
    padding: 0;
    & li {
      display: flex;
      justify-content: flex-start;
      margin: 5px 5px;
      min-height: 40px;
      align-items: center;
      border-radius: 5px;
      color: var(--matterColorText);
      transition: var(--transitionStyleButton);

      &:hover,
      &:focus {
        color: var(--matterColorLight);
        text-decoration: none;
        background-color: var(--marketplaceColor) !important;
      }
    }
  }
}

.noticeBoard {
  width: 100%;
  overflow: hidden;
  background-color: #fbebeb;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* padding: 10px 20px; */

  @media (min-width: 320px) and (max-width: 991.98px) {
    padding: 10px 10px;
    align-items: flex-start;
  }
}
.dissmissBtn {
  margin-left: 15px;
  /* font-weight: 500; */
  cursor: pointer;
  & svg {
    & path {
      stroke: #2f4858;
    }
  }
}

.notice {
  text-align: center;
  padding: 0 10px;
  color: #ec2a40;
  font-weight: 500;
  font-size: 14px;
  padding: 8px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  @media (min-width: 320px) and (max-width: 767.98px) {
    text-align: left;
    padding: 4px;
  }
  & span {
    @media (min-width: 320px) and (max-width: 767.98px) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      width: calc(100% - 15%);
    }
  }
  & button {
    padding: 0;
  }
}

.moreLess {
  border: none;
  color: var(--marketplaceColor);
  text-decoration: underline;
  @media (min-width: 768px) {
    display: none;
  }
}
/* .notice {
  text-align: center;
  padding: 0 10px;
  color: #ec2a40;
  font-weight: 500;
  font-size: 14px;
  padding: 8px;
} */
