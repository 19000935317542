@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    padding: 109px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 109px 0 82px calc((100% - 1056px) / 2);
  }
}

/* .tabs {
  display: flex;
  flex-direction: row;s
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  @media (--viewportMedium) {
    min-height: 56px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    padding-bottom: 10px;
  }
  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
  }
} */
/* 
.tab {
  display: flex;
  align-items: flex-end;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
} */
.tabs {
  /* padding-top: 2px; */
  padding-top: 0;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 10px;
  /* padding-right: 50px; */
  @media (--viewportMedium) {
    padding-top: 0;
    /* padding-top: 12px; */
    padding-top: 20px;
  }

  @media (--viewportLarge) {
    padding-top: 0;

    /* padding-top: 53px; */
    flex-direction: column;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}
