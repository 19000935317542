@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow);
  @media (min-width: 767px) {
    display: none;
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--Topbar_logoHeight)) / 2) 0px;
  /* & img {
    @media (min-width: 320px) and (max-width: 575.98px) {
      height: 20px;
      width: 80px;
    }
  } */
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  /* Layout */
  display: flex;
  /* width: 66px; */
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  /* padding: 15px 5px 15px 24px; */
  padding: 15px 5px 15px 12px;
  position: relative;

  @media (max-width: 320px) {
    padding: 15px 5px 15px 24px;
  }
}

.searchMenu {
  padding: 12px 24px;
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 20px;
  height: 20px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  color: #fff;
  font-size: 12px;
  background-color: var(--failColor);
  border: 2px solid var(--matterColorLight);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;

  @media (min-width: 320px) and (max-width: 575.98px) {
    width: 18px;
    height: 18px;
  }
  @media (max-width: 319px) {
    display: none;
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;
  /* padding:10px 0; */
  @media (min-width: 767px) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--matterColor);
}
.rootSearchIcon {
  stroke: var(--matterColor);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--matterColorAnti);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.reminderModalLinkButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: 4px;
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.currencyDropdown {
  margin-left: auto;
  margin-right: 10px;

  @media (min-width: 320px) and (max-width: 375.98px) {
    /* max-width: 80px; */
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    margin-left: 20px !important;
  }
}

.rightIcons {
  display: flex;
  align-items: center;
  white-space: nowrap;
  /* margin-left: auto; */
  margin-right: 5px;
  /* display: flex;
  align-items: center; */
  @media (min-width: 320px) and (max-width: 475.98px) {
    /* margin-left: auto; */
    /* margin-left: 5px; */
    margin-right: 10px;
  }
}
.createNewListingLink {
  margin-left: auto;
  white-space: nowrap;
  & a {
    & > span {
      color: #000000;
    }
    & svg {
      width: 30px;
      display: block;
      height: 25px;
      & path {
        fill: #000000;
      }
      @media (max-width: 575px) {
        width: 20px;
        display: block;
        height: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    /* margin-left: 20px !important; */
  }
}
.svgIcons {
  fill: none;
  margin: 0 6px;
  /* margin: 0 15px; */
  & path {
    stroke: #000000;
  }
  @media (min-width: 376px) and (max-width: 575px) {
    margin: 0 6px;
    width: 22px;
  }

  @media (min-width: 320px) and (max-width: 375.98px) {
    /* width: 15px;
    margin: 0 3px;

    display: inline-block; */
  }
  @media (max-width: 319.98px) {
    display: none;
  }
}
.mobileSearchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  background-color: #ffffff;
  border-top: 3px solid var(--matterColorNegative);
  @media (min-width: 767px) {
    display: none;
  }
  @media (max-width: 575.98px) {
    padding: 10px 10px;
  }
}
.mobileSearchForm {
  display: flex;
  max-height: 42px;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
  }
}

.topBarMobileScrollLayer {
  width: 85vw;
}

.topBarMobileContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);
  background-color: var(--matterColorLight);
  /* border-radius: var(--borderRadius); */
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom: none;
  /* height: 100%; */
  max-height: calc(100vh - 25px);
  box-shadow: 10px 10px 30px #c4c0c071;
  background-color: #f9fcff;
  overflow-y: auto;

  & > button {
    /* margin-right: 15vw; */
    & span {
      display: none;
    }
  }
}
.cartIcon {
  position: relative;

  & .notificationDot {
    right: -2px;
    top: -7px;
    @media (min-width: 576px) {
      right: 5px;
      /* top: -15px; */
    }
    @media (min-width: 320px) and (max-width: 575.98px) {
      right: -7px;
      top: -10px;
    }
  }
}

.topbarRightDiv {
  display: flex;
  align-items: center;
}

.profileIcon {
  position: relative;
}
