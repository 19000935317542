@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}
.textInput {
  flex-shrink: 0;
  margin: 16px 0px 16px;
  position: relative;
  & input {
    min-height: 54px !important;
  }

  & input,
  & textarea {
    border-bottom: 0px;
    border-radius: 20px;
    /* resize: auto; */
    resize: vertical !important;
    min-height: 77px;

    max-height: 350px;
    border: 1px solid #c7c7c7;
    /* border: 1px solid var(--marketplaceColor); */
    padding: 20px 18px;
    box-sizing: border-box;
    /* border-radius: var(--borderRadius8); */
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColorBlack);
    @media (min-width: 320px) and (max-width: 575.98px) {
      border-radius: 15px;
      max-height: 146px;
      height: 77px;
      font-size: 14px;
    }
  }
  & label {
    font-weight: 600 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: #b5b5b5;
    font-size: 14px !important;
  }

  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.textarea {
  composes: h4 from global;
  border-bottom-width: 0;

  /* Avoid text going behind the submit button */
  padding: 25px 25px 25px 25px;

  margin: 0;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: calc(100% - 52px);

  /* border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);
  background-color: transparent; */
  border-radius: 20px;
  /* resize: auto; */
  resize: vertical !important;
  min-height: 90px;

  max-height: 350px;
  border: 1px solid #c7c7c7;
  /* border: 1px solid var(--marketplaceColor); */
  /* padding: 20px 18px; */
  & :hover {
    border: 0;
    border-bottom-color: none;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  /* display: flex;
  flex-direction: row; */
  & input{
    display:none
  }
}

.errorContainer {
  display: block;
  flex: 1;
  /* text-align: right; */
  padding: 26px 24px 0 0;
}

.error {
  composes: h5 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  composes: button buttonText buttonBordersSecondary buttonColorsSecondary from global;

  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: inline-block;
  margin: 17px 0 0 0;
  width: auto;
}

.sendIcon {
  margin: -3px 5px 0 0;
}

.previewImage {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}

& .previewImageWrapper {

  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
  /* width: 168px; */
  /* height: 168px; */
  width: 130px;
  height: 130px;
  aspect-ratio: 1;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    background-color: #e9e8e8;
  }
  & .removeImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    cursor: pointer;

    & svg {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 10px;
      height: 10px;
      fill: var(--matterColorAnti);
      stroke: var(--matterColorAnti);
    }

    &:hover svg {
      fill: var(--matterColorLight);
      stroke: var(--matterColorLight);
    }
  }
  @media(max-width:575px){
    width: 120px;
    height: 120px;
    margin-right:0;

  }
  @media(max-width:480px){
    width: 110px;
    height: 110px;


  }
  @media(max-width:393px){
    width: 95px;
    height: 95px;
  }
}

.uploadButton{
  composes: button buttonText buttonBordersSecondary buttonColorsSecondary from global;

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 17px 0 0 0;
  width: auto;
  margin-right: 10px;
  & svg{
    fill:none;
    margin:5px;
  }
}

.uploadLabel{
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  font-weight: var(--fontWeightMedium);
  cursor: pointer;
}

.imageContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
}
.invalidMessage{
  display: flex;
  color:var(--failColor);
  font-size: 15px;
  margin-left:15px;
  gap:5px
}
.maxSizeReminder{
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
    background-color: var(--marketplaceColor) !important;
    color:var(--matterColorLight) !important;
    position: absolute;
    /* left: 0; */
    top:0;
    right: 0;
    /* bottom: 64px; */
    border-radius: 5px;
    z-index: 1;
    padding: 10px;
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* transform: translate(-100%, 0); */
    box-shadow: var(--boxShadowCheckbox);
    /* width: 100vw; */
    animation: slideFromBottom 0.6s ease-in-out;
    animation-fill-mode: forwards;
  }
  @keyframes slideFromBottom {
    from {
      transform: translate(0, 20%);
      opacity: 1;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .buttonContainer{
    display:flex;
    justify-content: flex-end;
  }