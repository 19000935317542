@import '../../styles/customMediaQueries.css';

.accPageContainer {
  & > div:nth-child(2) {
    max-width: 1400px;
    margin: auto;
    & div {
      border: 0;
    }
    & > div:nth-child(2) {
      padding-top: 70px;

      @media (min-width: 768px) and (max-width: 991.98px) {
        padding: 20px 40px 40px !important;
      }

      @media (min-width: 320px) and (max-width: 767.98px) {
        padding: 20px 25px 40px !important;
      }
    }
  }
}
.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  /* padding: 0; */
  flex-shrink: 0;
  /* background-color: var(--matterColorLight); */
  background-color: #f9fcff;

  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;
  padding: 10px 20px;
  margin: 0;
  min-width: 300px;
  @media (--viewportLarge) {
    /* padding: 128px 0 82px 36px; */
    margin: 70px 0 82px 36px;
    padding: 10px 20px;
    flex-direction: column;
    overflow-x: auto;
    /* background-color: #f9fcff; */
    box-shadow: none;
    border-top: none;
  }

  @media (min-width: 320px) and (max-width: 575.98px) {
    padding: 10px 0px;
    & > nav {
      padding: 10px 10px;
    }
  }
}
.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 25px;
  font-weight: 400;
  @media (--viewportMedium) {
    margin-bottom: 0px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.tabInfo {
  font-size: 16px;
  font-weight: 400;
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 14px;
    /* margin-bottom: 5px; */
  }
}
