.priceType {
  /* border-radius: 10px; */
  @media (min-width: 415px) {
    min-width: 100px;
    margin-left: 30px;
  }
  @media (max-width: 319px) {
    display: none;
  }
  & > div{
    border-radius: 50px;
    width: max-content;
    border: 2px solid #000;
    & > div{
      & > span{
        display: none !important;
      }
      & > div{
        & > svg{
          fill:#000;
        }
      }
    }
  }
}
