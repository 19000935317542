@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;
  padding: 0 50px;
  @media (--viewportMedium) {
    margin-top: 32px;
  }

  @media (min-width: 1400px) {
  }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    padding: 0 40px;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    padding: 0 20px;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    padding: 0;
  }
  @media (min-width: 320px) and (max-width: 575.98px) {
    padding: 0;
  }
}

.sectionContainer {
  padding: 0;
  display: flex;
  align-items: center;
  @media (--viewportMedium) {
    padding: 0;
  }
}

/* 
.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
} */

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
}

.label {
  /* width: var(--ProfileSettingsForm_avatarSize); */
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: #edebe9;
  border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  cursor: pointer;
  @media (min-width: 768px) {
    width: 155px;
    height: 155px;
  }
  & > div {
    & > img {
      /* width: auto; */
      /* height: auto; */
      @media (min-width: 320px) and (max-width: 575.98px) {
        width: 76px;
        height: 76px;
      }
    }
  }
}

.avatarPlaceholder {
  border-style: dashed;
  border-color: var(--matterColorDark);
  border-width: 2px;
  width: 155px;
  height: 155px;
  transition: var(--transitionStyleButton);
  @media (max-width: 767px) {
    width: 76px;
    height: 76px;
  }

  &:hover {
    border-color: var(--matterColorAnti);
  }
}
.UserSvg {
  fill: none;
  height: 50px;
  width: 35px;
  & path {
    stroke: #939291;
  }
  @media (max-width: 768px) {
    height: 20px;
    width: 35px;
  }
}
.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  position: absolute;
  /* bottom: 27px; */
  /* width: 105px; */
  width: 41px;
  height: 41px;
  padding: 11px 10px 7px 35px;

  /* Look and feel (buttonish) */
  /* background-color: var(--matterColorLight); */
  /* background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>'); */
  background-repeat: no-repeat;
  background-position: 15px 12px;
  /* border: solid 1px var(--matterColorNegative);
  border-radius: 2px; */

  margin-top: 0;
  margin-bottom: 0;

  text-transform: uppercase;
  color: var(--marketplaceColor);
  white-space: nowrap;
  text-decoration: underline;
  @media (--viewportMedium) {
    /* Position: under */
    /* bottom: -10px;
    right: auto; */
    /* bottom: -10px; */
    right: -180px;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 11px 10px 7px 35px;
  }

  &:hover {
    /* border: solid 1px var(--matterColorAnti); */

    color: #000000;
  }
}

.uploadingImage {
  /* Dimensions */
  /* width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize); */

  width: 155px;
  height: 155px;

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    /* width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop); */

    width: 155px;
    height: 155px;

    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
  @media (max-width: 768px) {
    height: 76px;
    width: 76px;
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}
.sectionTitle {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 767.98px) {
    font-size: 16px;
    font-weight: 600;
  }
  @media (max-width: 575.98px) {
    font-size: 14px;
    font-weight: 500;
  }
}
/* .titleBlue,
.title {
  font-size: 20px;
  font-weight: 600;
} */
.titleBlue {
  color: var(--marketplaceColorDark);
  cursor: pointer;
}
.title {
  color: var(--matterColorText);
}
.tip {
  display: flex;
  flex-direction: column;
  line-height: 31px;
  @media (max-width: 767.98px) {
    margin: 0 0 0 50px;
  }
  @media (min-width: 768px) {
    margin: 0;
  }
  @media (max-width: 575.98px) {
    line-height: 21px;
    margin: 0 0 0 20px;
  }
}
.infoPara {
  margin-left: 10px;
  /* font-size: 12px; */
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    width: calc(100% - 15%);
  }
  & button {
    padding: 0;
  }
}
.fileInfo {
  color: var(--matterColorText);
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 767.98px) {
    font-size: 14px;
    font-weight: 400;
  }
  @media (max-width: 575.98px) {
    font-size: 12px;
    font-weight: 400;
  }
}
.moreLess {
  border: none;
  cursor: pointer;
  color: var(--marketplaceColor);
  text-decoration: underline;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 20px;
  }
  @media (max-width: 575.98px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}
.divContainer {
  margin-top: 24px;
  margin-bottom: 20px;
  @media (max-width: 575.98px) {
    margin-top: 0px;
  }
}
.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.inputs {
  margin-top: 8px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 8px;
  flex-basis: 49%;
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;

    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    left: 1.2rem !important;
    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}

/* custom textarea design css */
.textareaDiv {
  position: relative;
  margin: 20px 0px;

  & .subIcon {
    position: absolute;
    bottom: 24px;
    right: 4px;
    pointer-events: none;
    color: var(--matterColorBlack);
  }

  & .helpText {
    color: #19825c;
    font-size: 14px;
    line-height: 17px;
    margin-top: -10px;
    margin-left: 30px;
    font-weight: 500;
    display: block;
  }
}

.textInput {
  flex-shrink: 0;
  margin: 16px 0px 0;
  position: relative;

  & input {
    min-height: 54px !important;
  }

  & input,
  & textarea {
    border-bottom: 0px;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    /* border: 1px solid var(--marketplaceColor); */
    padding: 12px 18px;
    box-sizing: border-box;
    /* border-radius: var(--borderRadius8); */
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColorBlack);
  }
  & label {
    font-weight: 600 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: #b5b5b5;
    font-size: 14px !important;
  }
  & textarea {
    min-height: 136px;
    /* resize: vertical !important; */
    resize: none;
  }

  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}
.inputDiv {
  & .privateMsgDiv {
    color: var(--matterColorBlack);
    font-size: 13px;
    line-height: 17px;
    margin-top: -8px;
    margin-left: 4px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    & svg {
      margin-right: 8px;
    }
  }
}
.charCount {
  display: block;
  color: #19825c;
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0 0 20px;
}
.infoDiv {
  display: flex;
}
.infoIcon {
  fill: none;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  margin-top: 4px;
}

.btnDiv {
  display: flex;
  align-items: center;
  padding-top: 30px;

  @media (max-width: 575.98px) {
    justify-content: center;
  }
}
.submitButton {
  /* margin-top: 24px; */
  /* flex-basis: 20%; */
  width: 100%;
  max-width: 150px;
}
.viewProfile {
  display: flex;
  align-items: center;
  & span {
    margin-left: auto;
  }
}
.viewIcon {
  fill: none;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  & path {
    stroke: var(--marketplaceColor);
  }
}
.profileLink {
  margin-left: 10px;
}
.locationWrapper {
  margin-top: 24px;
  margin-bottom: 20px;
}
.locationDiv {
  border-radius: 30px;
  border: 1px solid var(--matterColorText);
  position: relative;
  height: 100%;
  min-height: 200px;
}
.locationText {
  position: absolute;
  & label {
    font-weight: 600 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 18px;
    padding: 0px 4px;
    color: #b5b5b5;
    font-size: 14px !important;
  }
  & input {
    border: none;
    padding: 10px 20px;
  }
}

.errMsg{
  font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--failColor);
}