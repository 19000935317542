.hsCodeContainer {
  position: relative;

  & .hscodeList {
    left: 0;
    top: 64px;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: absolute;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    z-index: 2;
    box-shadow: var(--boxShadowPopup);
    border-radius: 8px;
    padding: 16px 0px;

    & .error {
      padding: 4px 16px;
    }

    & .spinner {
      margin: auto;
    }

    & ul {
      margin: 0;

      & li {
        font-weight: 400;
        color: #000;
        font-size: 15px;
        line-height: 22px;
        padding: 4px 16px;
        margin-bottom: 4px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0px;
        }
        &:hover,
        &:focus {
          background-color: var(--marketplaceColorDark);
          color: #fff;
        }
      }

      & .selected {
        background-color: var(--marketplaceColorDark);
        color: #fff;
      }

      & .noHsCode {
        background-color: var(--matterColorNegative) !important;
        color: #000;
        cursor: not-allowed;
        &:hover,
        &:focus {
          color: #000;
          background-color: var(--matterColorNegative) !important;
        }
      }
    }
  }
}

.tooltipInfo {
  color: var(--marketplaceColor);
  font-size: 16px;
  line-height: 24px;
  padding: 0px 0px 16px;
  margin-top: -8px;

  & svg {
    width: 20px;
    height: 20px;
  }

  & > span:last-child {
    color: #000;
    font-weight: 400;
  }
}

.textInput {
  flex-shrink: 0;
  margin: 16px 0px 16px;
  position: relative;
  & input {
    min-height: 54px !important;
  }

  & input,
  & textarea {
    border-bottom: 0px;
    border-radius: 30px;
    /* resize: auto; */
    resize: vertical !important;
    min-height: 77px;

    max-height: 350px;
    border: 1px solid var(--matterColorText);
    /* border: 1px solid var(--marketplaceColor); */
    padding: 16px 18px;
    box-sizing: border-box;
    /* border-radius: var(--borderRadius8); */
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColorBlack);
    @media (min-width: 320px) and (max-width: 575.98px) {
      border-radius: 15px;
      max-height: 146px;
      height: 77px;
      font-size: 14px;
    }
  }
  & label {
    font-weight: 600 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: #b5b5b5;
    font-size: 14px !important;
  }

  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}

.divLabel {
  display: block;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin: 20px 0;
}

.error {
  color: var(--failColor);
}

.categoryReactSelect {
  width: 100%;
  /* margin-bottom: 20px; */
  border: 1px solid var(--matterColorDark) !important;
  border-radius: 30px !important;
  /* display: flex; */
  margin: 20px 0;

  & > div:nth-child(3) {
    /* border-color: var(--matterColorDark) !important;
    border-width: 0px !important;*/
    border-radius: 30px !important;

    border: none;
    box-shadow: none !important;
    height: 42px;

    @media (min-width: 320px) and (max-width: 575.98px) {
      height: 42px;
    }
    & > div:nth-child(1) {
      overflow: visible;
      min-height: 42px;
    }

    & > div:nth-child(2) {
      height: 42px;
    }

    & > div:last-child {
      & > span:first-child {
        display: none;
      }
    }
  }
}

.SCselectedPlaceholder {
  & .placeholder {
    @media (max-width: 767px) {
    }
  }

  & .selectedPlaceholder {
    left: -6px;
  }

  & .value {
    & > div:first-child {
    }

    & > div:last-child {
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
    }
  }

  & .blurredValue {
    & > div:first-child {
    }
  }
}

.dropDownIndicatior {
  color: var(--matterColorDark);
  font-size: 22px;
  cursor: pointer;
  position: relative;
  /* z-index: 21; */
  fill: none;
  margin: 0 20px 0 0;
  @media (min-width: 320px) and (max-width: 575.98px) {
    margin: 0 15px 0 0;
  }
}
.disabledIndicator {
  color: var(--wardrobeColorBrown);
}
.menu {
  border-radius: 0px !important;
  margin-top: 1px !important;
  width: calc(100% + 2px) !important;
  left: -1px !important;

  z-index: 20 !important;
  /* margin-bottom: 50px; */

  & > div:first-child {
    border-top-width: 0px;
    background-color: #fff;
    overflow-y: auto;
    scrollbar-width: default;
    scrollbar-color: #e1e1e1 transparent;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--matterColorDimGrey);
      border: 2px solid #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--wardrobeColorDimGrey);
      border-width: 0px;
      border-radius: 10px;
    }

    & > div {
      font-weight: 400;
      border-bottom: 1px solid transparent;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      height: 61px;
      font-size: 16px;
      /* Dimensions */
      width: 100%;
      margin: 0;
      padding: 4px 24px;
      /* Layout details */
      color: var(--matterColorText);
      text-align: left;
      transition: var(--transitionStyleButton);

      /* &:hover {
        background-color: var(--marketplaceColorLight);
      } */
      &:hover,
      &:active,
      &:focus {
        color: var(--matterColorText) !important;
        background-color: var(--marketplaceColorLight);
      }

      &::after {
        content: '';
        position: absolute;
        width: calc(100% - 24px);
        height: 1px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--matterColorPaleWhite);
      }
    }

    & > div:first-child {
      color: var(--wardrobeColorBrown) !important;
      background-color: var(--matterColorLight) !important;

      &:hover {
        background-color: var(--marketplaceColorLight) !important;
      }
    }

    & > div:last-child,
    & > div:first-child {
      &::after {
        background-color: transparent;
      }
    }
  }
}
.multiValueContainer {
  /* height:  !important; */
  height: auto !important;
  min-height: 42px !important;

  & .emptyPlaceholder {
    top: 10px !important;
    left: 0px !important;
  }

  & .selectedPlaceholder {
    top: -3px !important;
    left: calc(-10% + 2px) !important;
  }
  & > span:first-child {
    position: relative;
    display: block;
    left: -16px;
    line-height: 20px;
    top: -2px;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    top: -2px;
    & > div:not(:last-child) {
      margin: 0px 3px;
      display: inline-block;
      position: relative;
      top: 0px !important;

      & > div:first-child {
        font-size: 14px;
        line-height: 17px;
        padding: 0px;
        background-color: #fff;
        overflow: unset !important;
      }
      & > div:last-child {
        display: none;
      }
    }
    & > div {
      position: relative;
      &::after {
        content: ',';
        position: absolute;
        right: -4px;
        bottom: -6px;
      }
    }

    & > div:nth-last-child(2) {
      &::after {
        display: none;
      }
    }
    & > div:nth-child(n + 4) {
      display: none;
    }
    & > div:nth-child(3) {
      &::after {
        content: '...';
        right: -16px;
        font-weight: 500;
        font-size: 18px;
      }
    }
    & > input:last-child {
      margin-left: 10px;
      position: absolute;
    }
  }
}
.valueContainer {
  /* height: 40px;
  padding: 2px; */
  position: relative;
  height: 40px;
  width: 100%;
  cursor: pointer;
  padding-left: 0.1rem;
  /* margin-top: 4px; */
  margin-left: 2px;
  & .placeholder {
    /* color: var(--wardrobeColorDarkGray); */
    color: #b5b5b5;
    font-weight: var(--fontWeightSemiBold);
    /* font-weight: 400; */
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    top: 9px;
    left: 0px;
    /* padding-left: 0.4rem; */
    z-index: 2;

    @media (max-width: 767px) {
      top: 8px;
    }
  }

  & .selectedPlaceholder {
    /* transform: scale(0.9); */
    transform: translateY(-145%) scale(0.95) !important;
    top: 16px;
    left: 0px;
    background-color: #ffffff;
    padding: 0 2px;
    @media (max-width: 767px) {
      transform: translateY(-155%) scale(0.95) !important;
      top: 20px;
    }
  }
  & .selectedSCPlaceholder {
    transform: scale(0.8);
    top: -6px;
    left: -15px;
  }
  & .value {
    & > div:first-child {
      top: 4px;
      position: relative;
      font-size: 16px;
      color: var(--matterColorText);
      text-transform: uppercase;
      @media (max-width: 767px) {
        top: 4px;
      }
      @media (min-width: 320px) and (max-width: 575.98px) {
        font-size: 14px;
      }
    }

    & > div:last-child {
      position: absolute;
      z-index: 1;
      width: 100%;
      left: -2px;
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
      top: 0px;
      left: 0px;
    }
  }

  & .blurredValue {
    & > div:first-child {
      color: var(--wardrobeColorDarkGray);
    }
  }
}
.catSelectedPlaceholder {
  & .placeholder {
    @media (max-width: 767px) {
    }
  }

  & .selectedPlaceholder {
    left: -6px;
  }

  & .value {
    & > div:first-child {
    }

    & > div:last-child {
    }
  }

  & .nonEmptyValue {
    & > div:last-child {
    }
  }

  & .blurredValue {
    & > div:first-child {
    }
  }
}

.searchHsCodeBtn {
  color: var(--marketplaceColor);
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
}

.valueWrapper {
  & > div:first-child {
    top: 0px !important;
  }
}
