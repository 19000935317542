@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  & button {
    width: 100%;
    max-width: 254px;
    padding: 15px 0;
    margin-top: 30px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}
.inputs {
  margin-top: 8px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 8px;
  flex-basis: 47%;
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 22px;
    padding-right: 0.8rem;
    border: none;
    /* border: 1px solid #d9d9d9; */
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    /* padding-top: 15px; */
    background-color: #ffffff;

    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    left: 1.2rem !important;
    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.titleDiv {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
}
.bottomDiv {
  text-align: center;
  padding: 0 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  color: var(--matterColorText);
}
.tabLink {
  /* margin: 0 0px 30px 0px; */
  color: var(--marketplaceColor);
  font-weight: 600;

  &:hover {
    color: var(--marketplaceColorDark);
  }
}
.passwordDiv {
  position: relative;
}
.view {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 11px;
  right: 15px;
  fill: transparent;
  & path {
    stroke: var(--matterColorText);
  }
  &:hover {
    cursor: pointer;

    & .hide {
      display: block;
    }
  }
}
.hide {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 11px;
  right: 15px;
  fill: transparent;
  & path {
    stroke: var(--matterColorText);
  }
}
.emailRoot {
  width: 100%;
  flex-basis: 49%;
  position: relative;
  & label {
    position: absolute;
    left: 1.4rem;
    top: -14px;
    pointer-events: none;
    color: #b5b5b5;
    background-color: #ffffff;

    padding-right: 2px;
    padding-left: 2px;
  }
  & input {
    margin-top: 20px;
    margin-bottom: 0;
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;
    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
