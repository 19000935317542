@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.card {
  composes: marketplaceInputStyles from global;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 38px;

  /* Border */
  /* border-bottom-color: var(--attentionColor); */
  border: 1px solid var(--matterColorText);
  padding-left: 22px;
  padding-right: 0.8rem;
  border-radius: 30px;
  & :hover {
    border: 0;
  }
}
.paypalClass {
  margin-top: 20px;
}
.cardSuccess {
  /* border-bottom-color: var(--successColor); */
}

.cardError {
  /* border-bottom-color: var(--failColor); */
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: -12px;
  margin-bottom: 16px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 44px 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 0px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 20px 0 10px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 15px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }

  & label {
    padding-top: 0;
    & > span:first-child {
      &:hover {
        color: var(--marketplaceColor);
      }
    }
    & span:last-child {
      margin: 0;
    }
  }
}

.saveForLaterUseLabel {
  composes: h5 from global;

  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  composes: h5 from global;

  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;
  font-weight: 400;
  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 24px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin: 0;
  margin-bottom: 15px;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 15px;
    padding: 0;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.paymentInfo {
  composes: h5 from global;

  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 12px;

    margin: 0;
    padding: 0;
  }
}

.paypalPaymentInfo {
  composes: h5 from global;

  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 20px 0px;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 12px;

    margin: 15px 0;
    padding: 0;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}
.sectionDiv {
  margin-bottom: 20px;
  & p {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin: 0 0 15px;
  }
}
.heading {
  font-weight: 400 !important;
  color: #000;
  margin-bottom: 15px;
  margin-top: 0;
  @media (min-width: 320px) and (max-width: 1023.98px) {
    margin-top: 20px;
  }
}
.userAddress,
.paymentSelectDiv {
  cursor: pointer;

  & .icon {
    width: 16px;
    height: 16px;
    fill: transparent;
    flex-shrink: 0;
    margin-right: 8px;
  }

  & .text {
    font-weight: 400;
    color: #000;
    font-size: 14px;
    line-height: 21px;
    flex-grow: 1;
    margin-right: 8px;
  }

  & .editBtn {
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    border: 0px;
    color: var(--marketplaceColor);
  }
}
.userAddress {
  display: flex;

  flex-direction: row;
  align-items: center;
  border-radius: var(--borderRadius);
  border: 1px solid var(--marketplaceColor);
  padding: 16px;

  min-height: 82px;
  margin-bottom: 16px;
  & label {
    padding: 0;
  }
}
.paymentTypeDiv {
  display: flex;
  flex-direction: column;
  border-radius: var(--borderRadius);
  border: 1px solid var(--marketplaceColor);
  padding: 16px;
  margin-bottom: 16px;
  min-height: 82px;
  cursor: pointer;
}
.paymentSelectDiv {
  margin: auto 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  & label {
    padding: 0;
  }
}
.selectedAddressType,
.selectedPaymentType {
  background-color: #f8fbff;
}
.defaultRadioBtn {
  width: 100%;
  cursor: pointer;
  & label {
    padding-top: 0;
    & > span:first-child {
      display: none;
    }
    & span:last-child {
      margin: 0;
    }
  }
}
.inputs {
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  flex-basis: 49%;
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;

    @media (min-width: 320px) and (max-width: 575.98px) {
      height: 37 px;
    }
    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
  & textarea {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    left: 1.2rem !important;
    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;

    @media (min-width: 320px) and (max-width: 575.98px) {
      transform: translateY(-160%) scale(0.95) !important;
    }
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}

.innerSectionDiv {
  margin-top: 24px;
  margin-bottom: -12px;

  & .heading {
    font-weight: 600 !important;
    color: var(--matterColorAnti);
  }
  & .addressType {
    border-width: 0px;
    padding: 8px;
    min-height: unset;
  }

  & .addressType label span {
    font-weight: 400;
  }
}

.paymentTypeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;

  & input {
    display: none;
  }
  & .paymentTypeOption {
    flex-basis: 48%;
    max-width: 48%;

    & label {
      padding-top: 0;
      cursor: pointer;

      &:hover .optionLabel,
      &:active .optionLabel {
        color: var(--marketplaceColor);
        border-color: var(--marketplaceColor);
      }

      & > span {
        display: none;
      }

      & > span:last-child {
        display: block;
        width: 100%;
      }
      & .optionLabel {
        width: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid var(--matterColorTextLight);
        border-radius: 8px;
        padding: 20px 16px;
        color: var(--matterColorTextLight);

        & .typeIcon {
          width: 16px;
          height: 16px;
          margin-bottom: 8px;
        }
        & .typeText {
          color: inherit;
        }
      }

      & .selectedOpitonLabel {
        color: var(--marketplaceColor);
        border-color: var(--marketplaceColor);
      }
    }
  }
}

.sameAddresCheckbox {
  margin: 0px 0px 16px;
  display: block;
  @media (max-width: 768px) {
    margin: 0px 0px 16px;
  }
}

.mPessaButton {
  /* border: 1px solid var(--marketplaceColor);
  outline: none;
  width: 100%;
  padding: 6px;
  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  border-radius: 8px; */
  margin-top: 16px;

  & img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  /* &:hover,
  &:focus {
    background-color: var(--matterColorLight);
    color: var(--matterColor);
  } */
}

.mpaisIcon {
  width: 48px;
  height: 48px;
  & path {
    fill: var(--matterColorLight);
  }
}
