@import '../../styles/customMediaQueries.css';
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 50px;
  @media(max-width:767px) {
    margin-bottom:15px;
  }
  @media(max-width:991px){
    margin-bottom: 30px;
  }
  & > span:nth-child(1){
    font-size:40px;
    color:var(--matterColorBlack);
    font-family: var(--marketplaceFontInter);
    @media(max-width:767px) {
      font-family: Cambria;
      font-size: 33.57px;
      font-weight: 400;
      line-height: 38.12px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    @media(max-width:991px){
      font-size: 29.37px;
      font-weight: 400;
      line-height: 33.35px;
      text-align: center;
    }
  }
}

.steps {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-top:20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  @media (--viewportMedium) {
    min-width: 30%;
    width: auto;
  }
  & > svg{
    width:35px;
    height:35px;
  }
  @media(max-width:767px){
    align-items: center;
    text-align: center;
  }
  
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 22px;
  font-weight: 600;
  line-height:35px;
  font-family: var(--marketplaceFontInter);
  @media(max-width:767px){
    font-size: 15.88px;
    font-weight: 600;
    line-height: 25.34px;
    /* letter-spacing: 0.09744108468294144px; */
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  @media(max-width:991px){
    font-size: 15.88px;
    font-weight: 600;
    line-height: 25.34px;
    letter-spacing: 0.09744108468294144px;
  }
  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}
.stepText {
  /* margin-top: 18px; */
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height:27px;
  font-family: var(--marketplaceFontInter);
  @media (max-width: 767.98px) {
    /* margin-top: 21px; */
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 11.55px;
    font-weight: 400;
    line-height: 19.63px;
    /* letter-spacing: 0.11548571288585663px; */
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
  @media(max-width:991px){
    font-size: 11.55px;
    font-weight: 400;
    line-height: 19.63px;
    letter-spacing: 0.11548571288585663px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
.homeSvg {
  & path {
    stroke-width: 3px;
    /* fill: white; */
    stroke: white;
  }
}
.iconSvg {
  fill: none;
  height: auto;
  width: 3.4em;

  & path {
    stroke-width: 3px;
  }
  & rect {
    stroke-width: 3px;
  }
}
.browseBtnDiv {
  background-color: white;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-radius: 15px;
  @media(max-width:575px){
    margin-top: 0;
  }
}
.browseBtn {
  width: 140px;
  background-color: var(--matterColorLightBlue) !important;
  border: 1.7px solid #222222;
  /* color: var(--marketplaceColor); */
  color: var(--matterColorBlack) !important;
  border-radius: 20px;
  font-size: 16px;
  font-size: 15.28px;
  font-weight: 600;
  line-height: 20.38px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-family: var(--marketplaceFontInter);

  @media(max-width:767px){
    font-size: 12.99px;
    font-weight: 600;
    line-height: 17.32px;
    text-align: center;
    /* text-underline-position: from-font; */
    text-decoration-skip-ink: none;
  }
  @media(max-width:991px){
    font-size: 12.99px;
    font-weight: 600;
    line-height: 17.32px;
    text-align: center;
  }
}
.lastLine{
  margin-top:50px;
  /* font-family: Inter; */
  font-size: 16.98px;
  font-weight: 600;
  line-height: 22.59px;
  letter-spacing: 0.0806659683585167px;
  text-align: center;
  /* text-underline-position: from-font; */
  text-decoration-skip-ink: none;
  font-family: var(--marketplaceFontInter);

  @media(max-width:767px){
    font-size: 14.44px;
    font-weight: 600;
    line-height: 19.21px;
    /* letter-spacing: 0.06856965273618698px; */
    text-align: center;
    /* text-underline-position: from-font; */
    text-decoration-skip-ink: none;
    margin-bottom: 20px;
  }
  @media(max-width:991px){
    font-size: 14.44px;
    font-weight: 600;
    line-height: 19.21px;
    letter-spacing: 0.06856965273618698px;
  }
}