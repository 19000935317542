@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 15px;
}

@media (--viewportMedium) {
  .root {
    margin-top: 20px;
  }
}

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
}

.radioButtonRow > :first-child {
  margin-right: 36px;
}

.selectCountry {
  position: relative;

  margin-bottom: 24px;
  & select {
    border-radius: 30px;
    border: 1px solid var(--matterColorText);
    padding: 5px 20px;
  }
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  margin-bottom: 12px;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 5px;
    margin-bottom: 0px;
  }
}
/* .bottomWrapper {
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    margin-top: 96px;
  }
} */
/* .submitBtn {
  flex-basis: 30%;
} */
.missingStripeKey {
  color: var(--failColor);
}

.accountInformationTitle {
  composes: h3 from global;
  font-weight: 500;
  margin: 15px 0 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

@media (--viewportMedium) {
  .accountInformationTitle {
    padding-top: 0px;
    padding-bottom: 4px;
  }
}

.savedCountry {
  padding-top: 0px;
  padding-bottom: 0px;
}
@media (--viewportMedium) {
  .savedCountry {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedInformation {
  margin-bottom: 24px;
  font-weight: 400;
}

.savedBankAccount {
  width: 100%;
  /* border-bottom: 2px solid var(--successColor); */
  border: 1px solid var(--matterColorText);
  border-radius: 30px;
  padding: 5px 20px;
  margin-top: 10px;
  margin-bottom: 24px;
  /* padding: 4px 0 10px 0; */
  color: var(--matterColor);
  text-align: left;

  &:hover {
    cursor: text;
    text-decoration: none;
    /* border-color: var(--matterColor); */
  }

  &:focus {
    outline: none;
    /* border-color: var(--matterColor); */
  }
}
.subTitle {
  font-size: 25px;
  font-weight: 400;
}
.subHeading {
  font-size: 18px;
  font-weight: 400;
}
.radioBtn {
  flex-basis: 50%;
  margin-right: 0 !important;
  display: flex;
  align-items: center;
  & svg {
    margin: 0 0px 0 0;
    fill: none;
    cursor: pointer;
  }

  & label {
    cursor: pointer;

    & span {
      /* font-size: 12px !important; */
      & svg {
        display: none;
      }
    }
  }
}
.customRadio {
  height: 16px;
  width: 16px;
}
