/*
If you need to add css to the Logo you can do it here.

.logoMobile {
}
*/
.logoImg {
  /* max-height: 120px; */
  /* max-width: 173px; */
  /* margin-top: -43px; */
  /* margin-left: -20px; */
  /* height: 30px; */
  max-width: 170px;
  /* max-height: 150px; */
  padding-top: 4px;
  @media (max-width: 991px) {
    max-width: 120px;
    /* max-height: 120px; */
    margin-left: 15px;
    padding-top: 4px;
  }
  @media (max-width: 575px) {
    max-width: 100px;
    margin-left: 10px;
  }
  @media (max-width: 390px) {
    max-width: 90px;
    margin-left: 10;
    padding-top: 5px;
  }
}
