@import '../../styles/customMediaQueries.css';

.root {
  & p {
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    font-weight: 400;

    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }
}
.heading {
  margin: 5px 0 0px 0;
  font-size: 25px;
  font-weight: 400;
  @media (--viewportMedium) {
    margin: 8px 0 0px 0;
  }
}
.subHeadings {
  display: block;
  margin: 20px 0 0px 0;
  font-size: 20px;
  font-weight: 500;
}
.lastUpdated {
  composes: marketplaceBodyFontStyles from global;
  margin-top: 0;
  margin-bottom: 55px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 54px;
  }
}
/* .listBullets {
  list-style-type: circle;
  list-style-position: inside;
} */
.ulTitle {
  margin: 20px 0 10px 0;
  font-size: 20px;
  font-weight: 600;
}
.boldTitle {
  display: block;
  margin-top: 20px;
  font-weight: 600;
}
.listBulletsOutside {
  list-style-position: outside;
  margin-top: 0 !important;
  margin-left: 15px !important;
  font-size: 15px;
  font-weight: 400 !important;
}
.boldText {
  color: var(--matterColorTextLight);
}
.noBulletList {
  list-style-type: none;
  font-weight: 400;
}
.noBulletListWithMargin {
  list-style-type: none;
  font-weight: 400;
  margin-left: 21px;
}
