@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  @media (min-width: 320px) and (max-width: 574.98px) {
    margin-top: 20px;
  }
}

.card {
  composes: marketplaceInputStyles from global;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 38px;
  margin-top: 10px;
  /* Border */
  /* border-bottom-color: var(--attentionColor); */
  border: 1px solid #292929 !important;
  padding-left: 22px;
  padding-right: 0.8rem;
  border-radius: 30px;
  & :hover {
    border: 0;
  }
}

.cardSuccess {
  border: 1px solid var(--marketplaceColor);
}

.cardError {
  border: 1px solid var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 14px 0;
  color: var(--matterColorAnti);

  padding-top: 8px;
  padding-bottom: 0px;

  @media (--viewportMedium) {
    margin: 0 0 26px 0;
  }
}

.afterpay {
  margin-top: 32px;
}

.billingHeading {
  margin: 0 0 14px 0;
  /* color: var(--matterColorAnti); */
  font-size: 18px;
  font-weight: 400;
  padding-top: 3px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 26px 0;
  }
}

.paymentLabel {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  @media (--viewportMedium) {
    margin-bottom: 2px;
  }
  @media (--viewportLarge) {
    margin-bottom: 4px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 40px 0 14px 0;

  padding-top: 4px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    margin: 41px 0 26px 0;
  }
  @media (--viewportLarge) {
    margin: 40px 0 26px 0;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;
  margin-top: 48px;
  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.infoText {
  composes: h5 from global;

  color: var(--matterColorAnti);
  padding: 0;
  margin: 14px 0 0 0;
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.paymentAddressField {
  padding-top: 38px;
}

.formRow {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;
}

.postalCode {
  margin-top: 24px;
  width: calc(40% - 9px);
}

.city {
  margin-top: 24px;
  width: calc(60% - 9px);
}

.field {
  margin-top: 24px;
}
.inputs {
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  flex-basis: 49%;
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;

    @media (min-width: 320px) and (max-width: 575.98px) {
      height: 37 px;
    }
    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
  & textarea {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    left: 1.2rem !important;
    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;

    @media (min-width: 320px) and (max-width: 575.98px) {
      transform: translateY(-160%) scale(0.95) !important;
    }
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
