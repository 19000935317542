.imageWrapper{
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.serialNumber{
    background-color: var(--wardrobeColorWhite);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border:1px solid var(--wardrobeColorDeepGray);
  left:-10px;
  top:-10px;
  position: static;
}
.images{
  width:fit-content;
}