@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.reviewItem {
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
  width: 100%;
}

.avatar {
  flex-shrink: 0;
  margin-right: 22px;
  @media (max-width: 767px) {
    width: 96px;
    height: 96px;
  }
  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
}

.reviewContent {
  composes: h4 from global;
  font-style: italic;
  margin: 0;
  white-space: pre-line;
  word-break: break-word;
  @media (max-width: 575px) {
    margin-top: 20px;
  }
}

.reviewInfo {
  composes: h5 from global;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--marketplaceColor);
  text-transform: capitalize;
  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.previewImgWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;
  width: 100%;
  /* height: 200px; */
  /* overflow: hidden; */
  & img {
    width: 196px;
    height: auto;
    /* object-fit: contain; */
    /* width: 100%; */
    /* height: 100%; */
    object-fit: cover;
    margin-right: 8px;
    margin-bottom: 8px;
    /* border-radius: 4px; */
    border-radius: 10px;
    background-color: #f5f5f5;
    aspect-ratio: 1;
    /* box-shadow:; */
    @media (max-width: 575px) {
      /* width: 96px; */
    }
  }
  & > div {
    width: 100%;
    & button::before {
      color: #000;
    }
    & > div {
      & > div {
        & > div {
          @media (max-width: 575px) {
            /* width: auto !important; */
            /* transform: translate3d(-100px, 0px, 0px); */
            /* transform: none !important; */
            /* width: 204px !important; */
            /* margin-right: 8px !important; */
          }
        }
      }
    }
  }

  & :global(.slick-initialized .slick-slide) {
    @media (min-width: 768px) {
      width: auto !important;
    }
  }
  /* & :global(slick-slide .slick-active slick-current) */

  & :global(.slick-slide.slick-active.slick-current) {
    @media (max-width: 767px) {
      width: auto !important;
    }
  }

  @media (max-width: 575px) {
    :global(.slick-dots) {
      bottom: -18px;
      & li {
        & button {
          width: 5px;
          height: 5px;
          &::before {
            background-color: #000;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            /* opacity: 1; */
            font-size: 0;
          }
        }
      }
    }
    :global(.slick-dots) {
      display: flex !important;
      bottom: -18px;
      justify-content: center;
      & li.slick-active {
        & button {
          &::before {
            opacity: 0.75 !important;
          }
        }
      }
    }
  }
}

.ReviewWrap {
  width: 90%;
}
.SmallImg {
  padding: 0 15px;
  /* width: 170px !important; */
  @media (max-width: 480px) {
    /* width: 190px !important; */
  }
  @media (max-width: 420px) {
    /* width: 180px !important; */
  }
  @media (max-width: 393px) {
    /* width: 165px !important; */
    /* padding: 0 10px !important; */
  }
  @media (max-width: 360px) {
    /* width: 135px !important; */
    padding: 0 10px !important;
  }
}
