@import '../../styles/customMediaQueries.css';

.root {
}

.verificiationBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 15px 32px 23px 32px;
  margin-bottom: 24px;

  border-radius: 4px;
  width: 100%;
}

@media (--viewportMedium) {
  .verificiationBox {
    flex-wrap: nowrap;
  }
}

.verificiationBoxTextWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0px 3px 0px;
  margin-bottom: 16px;
  gap: 10px;
  width: 100%;
  @media (min-width: 320px) and (max-width: 574.98px) {
    padding: 3px 0px 3px 0px;
  }
}

@media (--viewportMedium) {
  .verificiationBoxTextWrapper {
    margin-bottom: 0px;
  }
}

.verificationBoxTitle {
  composes: h4 from global;
  font-weight: var(--fontWeightSemiBold);
  display: flex;
  align-items: center;

  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: 320px) and (max-width: 574.98px) {
    /* flex-wrap: wrap; */
    font-size: 14px;
    align-items: flex-start;
  }
  & svg {
    flex-shrink: 0;
    /* width: 64px; */
    @media (min-width: 768px) {
      width: 25px;
    }
    /* @media (min-width: 475px) and (max-width: 767.98px) {
      width: 30px;
    } */

    @media (min-width: 320px) and (max-width: 767.98px) {
      width: 35px;
    }
  }
}
.messageContentDiv {
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: 320px) and (max-width: 574.98px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.verificationBoxText {
  composes: h4 from global;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
}

.getVerifiedButton {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  /* background-color: #2ecc71; */
  border-radius: 4px;
  min-height: 48px;
  min-width: 160px;
  width: 160px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* Verification required box */
.verficiationNeededBox {
  border: 1px solid var(--attentionColor);
  background: var(--attentionColorLight);
  /* background: var(--successColorLight); */
}

/* Verification error box */
.verficiationErrorBox {
  background: var(--failColorLight);
  border: 1px solid var(--failColor);
}

/* Verification success box */
.paypalVerficiationSuccessBox,
.verficiationSuccessBox {
  background: var(--successColorLight);
  /* border: 1px solid var(--successColor); */
  padding: 15px 24px;
  display: flex;
  /* justify-content: space-evenly; */
  align-items: flex-start;
  gap: 10px;
}
.verficiationSuccessBox {
  flex-direction: row;
  border: 1px solid #2ecc71 !important;
  align-items: center;
}
.paypalVerficiationSuccessBox {
  flex-direction: column;
}
.verificationBoxSuccessTextWrapper {
  margin: 0;
}
.editVerificationButton {
  composes: h4 from global;
  /* color: var(--matterColor); */
  font-weight: var(--fontWeightNormal);
  min-height: 48px;
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  /* background-color: var(--matterColorLight); */
  color: var(--marketplaceColorDark);
  /* width: 140px; */
  white-space: nowrap;
  padding: 10px 20px;
  border-radius: 5px;

  @media (min-width: 768px) {
    margin-left: auto;
    padding: 0;
  }
  @media (min-width: 575px) and (max-width: 767.98px) {
    margin-left: 15px;
  }
  @media (min-width: 320px) and (max-width: 574.98px) {
    padding: 10px 0;
    margin-left: 35px;
  }
  &:hover,
  &:focus {
    outline: none;
    /* color: var(--matterColorDark); */
    text-decoration: none;
    /* box-shadow: var(--boxShadowButton); */
    /* background-color: var(--marketplaceColorDark); */
    /* color: var(--matterColorLight); */
    /* color: var(--marketplaceColorDark); */
  }

  & span {
    pointer-events: none;
  }
}
.editPlanIcon {
  margin: 0px 3px 4px -5px;
  fill: none;
  width: 16px;
  height: 16px;
  & path {
    stroke: var(--marketplaceColor);
  }
}
.verificationNotStartedBtn {
  min-height: 48px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.icon {
  margin-right: 10px;
  @media (min-width: 768px) {
    margin-right: 10px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin-right: 0px;
  }
}

.iconEditPencil {
  stroke: var(--matterColor);
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--successColor);
  stroke-width: 3px;
}
