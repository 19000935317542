@import '../../styles/customMediaQueries.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media (min-width: 320px) and (max-width: 767.98px) {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
.disableBuy {
  /* display: none; */
  cursor: not-allowed;
  background-color: var(--marketplaceColorLight);
}
.title {
  /* Font */
  color: var(--matterColor);
  text-transform: capitalize;
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    font-size: 25px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 16px;
  }
}
.icon {
  fill: transparent;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-left: 5px;
  flex-shrink: 0;
  & path {
    stroke: var(--matterColorAnti);
  }
}
.topLine {
  /* display: block; */
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 10px;
  cursor: pointer;
  color: var(--matterColorDark);
  &:hover {
    color: var(--marketplaceColorDark);
  }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    padding: 0;
    margin-bottom: 20px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    padding: 0 24px;
    margin-bottom: 30px;
  }
}
.productForm {
  margin: 0 0px;
  @media (min-width: 768px) and (max-width: 1023.98px) {
    margin: 0;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 20px 0px;
  }
}
.noMargin {
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 20px 24px;
  }
}

.author {
  width: 100%;
  composes: h4 from global;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 30px;
  }
}
.desktopSpecialPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;

    flex-shrink: 0;
    margin: 20px 48px 0 0;
    padding: 0;
  }
}
.priceRow,
.priceRowMobile,
.sPriceRowMobile {
  display: flex;
}
.priceRow {
  align-items: end;
}
.priceRowMobile,
.sPriceRowMobile {
  align-items: flex-start;
}
.sPriceRowMobile {
  flex-direction: column;
  & > div:nth-child(1) {
    font-size: 12px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-shrink: 0;
    margin: 10px 48px 0 0;
    padding: 0;
  }
}
.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: underline;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.desktopPriceValue {
  /* Font */
  composes: h2 from global;
  color: var(--matterColorDark);
  font-size: 35px;
  font-size: 700;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit,
.strikedOutPrice,
.priceRowMobile {
  /* Font */
  composes: h5 from global;
  color: var(--matterColorDarkWhite);
  font-size: 16px;
  font-size: 400;
  text-transform: lowercase;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
    margin-left: 6px;
  }
}
.priceRowMobile {
  margin-top: 2px;
  margin-bottom: 0;

  & span {
    color: #000000;
    text-transform: uppercase;
  }
}
.strikedOutPrice {
  text-decoration: line-through;
  text-transform: uppercase;
  /* margin-left: 0; */
  margin: 0;
}
.bookingTitle {
  /* Font */
  /* composes: h3 from global; */
  color: var(--matterColorDark);
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 2px;
  text-transform: capitalize;
}

.bookingHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}
.productBookingForm {
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin-top: 20px;
    /* padding: 0 20px; */
    min-height: 39px;
  }
}
.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);
  align-items: center;
  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
  @media (min-width: 320px) and (max-width: 475.98px) {
    padding: 10px 24px 10px 16px;
  }
}

.priceContainer {
  /* Layout */
  flex-basis: 47%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin: auto;
  /* margin-right: 22px; */
  /* padding: 5px 12px; */
}

.priceValue {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (min-width: 320px) and (max-width: 475.98px) {
    font-size: 16px;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
  flex-basis: 47%;
  height: 42px;
  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  @media (min-width: 320px) and (max-width: 475.98px) {
    font-size: 14px;
  }
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}
.ratingDiv {
  margin: 00px 0 20px 0;
  & svg {
    width: 12px;
    height: 12px;
    margin-right: 3px;
  }
}
.poweredByDiv {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  & span {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    color: var(--matterColorDarkWhite);
  }
  @media (min-width: 320px) and (max-width: 1023.98px) {
    display: none;
  }
}
.cardImg {
  /* margin-right: 20px; */
}
.APcardImg {
  width: 90px;
  opacity: 40%;
}
.hDivider {
  display: block;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 40px;
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 20px;
  }
}
.providerInfoDiv {
  /* height: 100%; */
  margin: 30px 0;
  & > span {
    font-size: 18px;
    font-weight: 600;
    color: var(--matterColorTextLight);
    @media (min-width: 320px) and (max-width: 475.98px) {
      font-size: 14px;
    }
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 30px 20px;
  }
}
.imgNameDiv {
  display: flex;
  margin-top: 20px;
  align-items: center;
  & div {
    & > div:nth-child(2) {
      @media (min-width: 320px) and (max-width: 767.98px) {
        width: auto;
        height: auto;
      }
    }
  }
}
.reviewDetails {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  /* margin-left: 10px; */
  line-height: 30px;
}
.nameText {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 14px;
  }
}
.reviewStar {
  color: var(--matterColorTextLight);
  display: flex;
  align-items: center;
  & svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}
.authorContactDiv {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.seperatorDot {
  border-radius: 50%;
  background-color: var(--marketplaceColor);
  width: 6px;
  height: 6px;
  margin: 0 8px;
}
.logoDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mpaisIcon {
  width: 52px;
  height: 52px;
}
