@import '../../styles/customMediaQueries.css';

.root {
}

.newPasswordSection {
  margin-top: 16px;
  /* margin-bottom: 46px; */
  padding-top: 6px;
  position: relative;
  @media (--viewportMedium) {
    /* margin-bottom: 56px; */
    padding-top: 0;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  padding: 0;
  opacity: 0;
  margin-top: 56px;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorText);
  font-size: 25px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 25px;

  @media (--viewportMedium) {
    margin-bottom: 25px;
  }
}

.bottomWrapper {
  margin-top: 46px;
  /* display: flex; */
  @media (--viewportMedium) {
    margin-top: 96px;
  }
}
.submitBtn {
  /* flex-basis: 30%; */
}
.error {
  composes: h4 from global;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.helperLink {
  composes: a from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  text-decoration: underline;
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}
.inputs {
  margin-top: 8px;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 8px;
  flex-basis: 49%;
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding: 5px 20px;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;

    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    left: 1.2rem !important;
    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;
    @media (min-width: 320px) and (max-width: 767.98px) {
      transform: translateY(-155%) scale(0.95) !important;
    }
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.cPhide,
.cPview,
.view {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 12px;
  right: 15px;
  fill: transparent;
  & path {
    stroke: var(--matterColorText);
  }
  &:hover {
    cursor: pointer;

    & .hide {
      display: block;
    }
  }
}
.cPhide,
.cPview,
.hide {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 12px;
  right: 15px;
  fill: transparent;
  & path {
    stroke: var(--matterColorText);
  }
}
.cPhide,
.cPview {
  top: 18px !important;
}

.hide,
.view,
.cPhide,
.cPview {
  @media (min-width: 320px) and (max-width: 767.98px) {
    top: 25px !important;
  }
}
