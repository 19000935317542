.root {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorDark);
}

.mapRoot {
  width: 100%;
  height: 100%;
}
