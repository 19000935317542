@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

/* .listingCards {
  padding: 0 24px 96px 24px;
  justify-content: left;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
  }

  @media (--viewportLarge) {
    padding: 0 36px 96px 36px;
  }
} */
.listingCards {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 32%);
  grid-gap: 10px;

  @media (min-width: 320px) and (max-width: 575.98px) {
    grid-template-columns: repeat(auto-fill, 47%);
  }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    grid-template-columns: repeat(auto-fill, 47%);
  }
  @media (min-width: 1024px) and (max-width: 1199.98px) {
    grid-template-columns: repeat(auto-fill, 32%);
  }
  @media (min-width: 1200px) {
    /* grid-template-columns: repeat(auto-fill, 22%); */
    grid-template-columns: repeat(auto-fill, 24%);
  }
}
.listingCard {
  margin: 0 0 26px 0;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  overflow: hidden;
}
/* .listingCard {

     
  margin-bottom: 36px;
  flex-basis: 100%;
  flex-basis: 30%;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;  */
/* @media (--viewportLarge) { */
/**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
/* flex-basis: calc(50% - 12px); */
/* flex-basis: 30%;
    margin-right: 24px;
  } */

/* @media (--viewportXLarge) { */
/**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

/* flex-basis: calc(33.33% - 16px); */
/* flex-basis: 30%;
    margin-right: 24px;
  } */
/* } */

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

/* .listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
} */
/* 
.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
} */

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: 32px;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
