.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prevPageContainer,
.nextPageContainer {
  flex-basis: 33%;
  display: flex;

  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 23%;
  }
}
.nextPageContainer {
  justify-content: flex-end;
}
.prev,
.next,
.prevNextDisabled {
  display: block;

  /* Dimensions */
  padding-top: 26px;
  padding-bottom: 26px;
  text-transform: uppercase;
  color: var(--matterColorDark);

  &:hover {
    text-decoration: none;
  }

  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 12px;
    display: flex;
    align-items: center;
    font-size: 12px;

    & svg {
      display: inline-block;
      width: 8px;
    }
  }
}
.prevNextDisabled {
  color: var(--matterColorAnti);
  cursor: not-allowed;
}

.prev,
.prevNextDisabled {
  & svg {
    margin-right: 5px;
    @media (min-width: 320px) and (max-width: 575.98px) {
      width: 8px;
    }
  }
}
.next {
  /* Minimum touch size 44px (svg width + padding) */
  /* padding-left: 33px; */
  & svg {
    margin-left: 5px;

    @media (min-width: 320px) and (max-width: 575.98px) {
      width: 8px;
      height: auto;
    }
  }
}

.arrowIcon {
  /* Color for svg icons */
  /* fill: var(--marketplaceColor); */
  stroke: #000000;
  /* stroke: var(--marketplaceColor); */

  &:hover {
    /* fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark); */
  }
}

.disabled,
.disabled:hover {
  margin-left: 5px;

  fill: var(--matterColorAnti);
  stroke: var(--matterColorAnti);
}

.pageNumberList {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 12px;
    flex-basis: 43%;
    max-width: 43%;
  }
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
/* .pageNumberList1Items {
  flex-basis: 40px;
} */

/* .pageNumberList2Items {
  flex-basis: 60px;
} */

/* .pageNumberList3Items {
  flex-basis: 100%;
} */

/* .pageNumberList4Items {
  flex-basis: 160px;
} */

/* .pageNumberList5Items {
  flex-basis: 200px;
} */
/* 
.pageNumberList6Items {
  flex-basis: 250px;
} */

/* .pageNumberList7Items {
  flex-basis: 275px;
} */

.toPageLink {
  /* Dimensions */
  padding: 21px 5px;
  min-width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Color for svg icons */
  color: var(--matterColor);

  &:hover {
    text-decoration: none;
  }
}

.currentPage {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 10px; */
  background: var(--marketplaceColor);
  color: #ffffff;
  border-radius: 50%;
  min-width: 39px;
  height: 39px;
  text-align: center;
  padding: 21px 5px 18px 5px;
  @media (min-width: 320px) and (max-width: 575.98px) {
    margin: 0;

    padding: 0;
  }
}

.paginationGap {
  /* Dimensions */
  padding: 21px 5px;

  /* Color for svg icons */
  color: var(--matterColor);

  /* Ellipsis is raised from baseline */
  line-height: 13px;
}
