@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
    max-width: 1400px;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  min-width: 300px;
  /* padding: 0; */
  flex-shrink: 0;
  /* background-color: var(--matterColorLight); */
  background-color: #f9fcff;

  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;
  padding: 10px 20px;
  margin: 0;

  @media (--viewportLarge) {
    /* padding: 128px 0 82px 36px; */
    margin: 70px 0 82px 36px;
    padding: 10px 20px;
    flex-direction: column;
    overflow-x: auto;
    /* background-color: #f9fcff; */
    box-shadow: none;
    border-top: none;
  }

  @media (min-width: 320px) and (max-width: 575.98px) {
    padding: 10px 0px;
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    /* padding-left: 24px; */
    @media (min-width: 320px) and (max-width: 575.98px) {
      padding-left: 10px;
    }
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panel {
  flex-grow: 1;

  @media (--viewportLarge) {
    padding: 70px 36px 82px 59px;
    /* border-left: 1px solid var(--matterColorNegative); */
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 88px calc((100% - 1056px) / 2) 82px 59px; */
    padding: 70px 70px 82px 59px;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 24px;

  & > span:first-child {
    margin-right: 12px;
  }
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  font-size: 18px;
  line-height: 32px;
}

.modalBtn {
  display: block;
  margin: 24px auto 16px;
  background-color: var(--marketplaceColor);
  padding: 12px 0px;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  text-transform: capitalize;
  font-weight: 400;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: var(--marketplaceColorDark);
  }
}
