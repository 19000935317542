.selectField {
  padding: 0;
  position: relative;
  margin-bottom: 30px;
  margin-top: 15px;
  & label {
    margin-bottom: 5px;
  }
  & select {
    border: 1px solid black;
    border-radius: 30px;
    padding: 0 20px;
    @media (min-width: 320px) and (max-width: 767.98px) {
      min-height: 39px;
    }
  }
}
.title {
  /* Font */
  color: var(--matterColor);
  text-transform: capitalize;
  /* Layout */
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    margin-bottom: 15px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 16px;
    margin-top: 30px;

    margin-bottom: 15px;
  }
}
.deliveryField {
  padding: 0;
  margin-top: 24px;
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 20px 24px;
  }
}
.marginLessBreakdown {
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 20px 0px;
  }
}
.submitButton {
  padding: 0px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 72px;
  }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    margin-top: 80px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 50px 20px;
  }
}
.noMargin {
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 50px 0px;
  }
}
.disableBuy {
  & button {
    cursor: not-allowed;
  }
}
.finePrint {
  /* @apply --marketplaceTinyFontStyles; */
  text-align: center;

  @media (min-width: 320px) and (max-width: 767.98px) {
    margin: 0 20px;
  }
}

.error {
  /*@apply --marketplaceH4FontStyles;*/
  color: var(--failColor);

  padding: 0;
}

.addToCartBtn {
  margin-bottom: 16px;
  color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
  background-color: #fff;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--marketplaceColor);
    color: #fff;
  }

  &:disabled {
    background-color: var(--matterColorNegative);
  }
}
/* .cartBtnDisabled {
  &:hover {
    background-color: var(--matterColorNegative);
  }
} */
.oneProviderRoot {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  & .oneProviderWrapper {
    width: 100%;
    padding: 24px 32px;
    max-width: 560px !important;
    margin: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: var(--borderRadius15);

    @media (max-width: 576px) {
      padding: 24px;
    }

    & .closeBtn {
      width: 20px;
      height: 20px;
      padding: 0px;
      outline: none !important;
      border: 0px !important;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-top: -8px;
      margin-right: -12px;

      & > svg {
        width: 24px;
        height: 24px;
      }
    }

    & .heading {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      margin: 12px 0px;
      text-align: center;
    }

    & .subHeading {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      text-align: center;
    }

    & .closeTextButton {
      min-height: 48px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #fff;
      border: 1px solid var(--marketplaceColor);
      outline: none;
      cursor: pointer;
      border-radius: var(--borderRadius8);
      max-width: 280px;
      width: 100%;
      margin: 16px auto;
      background-color: var(--marketplaceColor);

      &:hover,
      &:focus,
      &:active {
        background-color: #fff;
        color: var(--marketplaceColor);
      }
    }
  }
}

.outOfStockWrapper {
  margin-top: 16px;
}

.bookRequestFailed {
  color: var(--failColor);
  text-align: center;
}

.inputs {
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  flex-basis: 49%;
  & label {
    position: absolute;
    left: 1.2rem;
    top: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #b5b5b5;
    transition: all 0.1s linear;
  }
  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 22px;
    padding-right: 0.8rem;
    border-radius: 30px;

    border: 1px solid var(--matterColorText);
    height: 42px;
    background-color: #ffffff;

    @media (min-width: 320px) and (max-width: 575.98px) {
      height: 37 px;
    }
    /* border-color: var(--wardrobeColorDimGrey); */
  }

  & input:focus + label {
    transform: translateY(-120%) scale(0.75);
    left: 0rem;
    /* top: 50px; */
  }
  & > div:nth-child(3) {
    /* display: none; */
    font-size: 12px;
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
  & textarea {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
.nonEmptyInputs {
  & label {
    transform: translateY(-120%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.fnNonEmptyInputs {
  & label {
    transform: translateY(-145%) scale(0.95) !important;
    left: 1.2rem !important;
    background-color: #ffffff;
    /* margin: 0 10px; */
    padding-right: 2px;
    padding-left: 2px;

    @media (min-width: 320px) and (max-width: 575.98px) {
      transform: translateY(-160%) scale(0.95) !important;
    }
  }

  & input {
    border-color: var(--wardrobeColorBlack);
  }
}
.bottomMarginInput {
  margin-bottom: 10px !important;
}
.maxQuantityMsg {
  margin-left: 10px;
}

.customQuoteBtn {
  margin-top: 15px;
}

.photoDiv {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 20px;
  cursor: pointer;
}

.previewImageWrapper {
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
  /* width: 168px; */
  /* height: 168px; */
  width: 175px;
  height: 155px;
  aspect-ratio: 1;
  & .vesselImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    background-color: #e9e8e8;
  }
  & .removeImg {
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 33px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    cursor: pointer;
    color: #ffffff;
  }
}

.uploadDocumentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  padding: 20px;
  border: 1px solid;
  border-style: dashed;
  border-radius: 10px;

  /* margin-top: 18px; */
  margin-bottom: 18px;

  @media (--viewportMedium) {
    /* margin-top: 24px; */
    margin-bottom: 16px;
  }
}
.disabled {
  border: 1px solid var(--colorGrey200);
  border-style: dashed;
  color: var(--colorGrey200);
}
.labelDocument {
  text-align: center;
}
.addressWrapper {
  display: flex;
  flex-direction: column;

  gap: 20px;
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 17px;
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.toolbarClassName {
  padding-bottom: 6px !important;
  border: 0 !important;
  border-bottom: 0.5px solid #2626264d !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  z-index: 100;
  & > div {
    & ul {
      background-color: #fff;
    }
  }
}
.wrapperClassName {
  border: 0.5px solid #2626264d;
  border-radius: 6px;
}
.editorClassName {
  color: #000 !important;
  font-family: var(--fontFamilyHelvetica) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-top: 0px !important;
  padding: 0px 16px !important;
  min-height: 112px !important;
  & ul,
  ol {
    margin: 0 !important;
    li {
      color: #000 !important;
      font-family: var(--fontFamilyHelvetica) !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      margin-top: 0px !important;
      padding: 0px 16px !important;
    }
  }
}

.editor {
  max-width: 456px;
}

.okBtn {
  margin-top: 40px;
}

.successMsg {
  margin-top: 20px;
}

.termsHeading {
  composes: h3 from global;
  margin: 0 0 19px 0;

  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}
