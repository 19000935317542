@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
  & > div:nth-child(1) {
    @media (max-width: 575px) {
      margin: 30px 24px 0;
      width: auto;
    }
    & div {
      & div {
        & label {
          @media (max-width: 767px) {
            margin: 0 22px;
          }
          @media (max-width: 575px) {
            margin: 0 18px;
            left: 0;
          }
        }
        & div {
          & div {
            & div {
              & > div:nth-child(2) {
                z-index: 9;
              }
            }
          }
        }
      }
    }
  }
  & > div:nth-child(2) {
    align-items: baseline;
    @media (max-width: 767px) {
      margin: 30px 24px 0;
      width: calc(100% - 48px);
    }
    & div {
      width: 49%;
      margin: 0;
      & div {
        & label {
          top: -3px;
          z-index: 2;
        }
      }
    }
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: #4a4a4a;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  composes: h4 from global;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: 15px 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 10pxpx;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.bookRequestFailed {
  color: var(--failColor);
  text-align: center;
}
