@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
  @media (min-width: 320px) and (max-width: 575.98px) {
    padding: 11px 15px 0 15px;
  }
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;
  font-size: 28px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0px;
  /* @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  } */
  @media (max-width: 767.98px) {
    margin-top: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 23px;
  }
}
.loginTitle {
  /* text-align: center; */
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  @media (max-width: 575.98px) {
    margin: 10px 0;
    text-align: center;
  }
}
.titleBlue,
.titleBlack {
  font-size: 25px;
  font-weight: 400;
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 20px;
  }
}
.titleBlue {
  color: var(--marketplaceColorDark);
}
.titleBlack {
  color: var(--matterColorText);
}
.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorText);
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 575.98px) {
    text-align: center;
    font-size: 14px;
    margin: 0 0 10px 0;
  }
}
.hDivider {
  border-bottom: 1px solid var(--matterColorDivider);
  margin-top: 10px;
  @media (max-width: 575.98px) {
    display: none;
  }
}
.titleDiv {
  composes: titleDiv from global;
}
.modalScroll{
  flex-grow: 1;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color:  rgba(0, 0, 0, 0.4); 

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (max-width:576px) {
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      background-color: var(--matterColorLight);
      background-image: none;
    }
}

.modalContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  & > button{display: none;}
  & .modalContent{
    margin: auto;
  }
   & .addressModalWrapper{
    
      background-color: #fff;
      width: 100%;
      max-width: 576px;
      padding: 16px 20px;
    }
  
    & h4{
      font-size: 18px;
      color:#000;
    }
  
    & .link{
      font-size: 18px;
      text-decoration: none !important;
      border-bottom: 1px solid var(--marketplaceColor);
    }
  
  
}