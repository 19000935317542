/* @import '../../styles/propertySets.css';
@import url("../../styles/marketplaceDefaults.css");

.incorrectInfoModalMain{
    /* background-color: var(--failColor) !important; */
/* } */
/* .showGeneralInfo{
    left:calc(100% - 258px) !important;
}
.generalInfoModalMain{
    background-color: var(--wardrobeColorPrussianBlue); */
    /* max-width: 248px;
    height: unset;
    padding: 2px 0px;
    /* top:calc(var(--topbarHeightDesktop) + 8px); */
    /* left:200%;
    border-radius: 4px;
    transition: left ease-out 0.35s;
    position: fixed !important;
    z-index: 3003;
    box-shadow: 0px 7px 14px #00000052;

    & .modalScrollLayer{
        width: 100%;
        height:100%;
    } */

    /* & .modalContainer{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        & > button:first-child{display: none;}

        & .modalContent{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin:auto;
            & .content{
                width: 100%;
                display: flex;
            flex-direction: row;
            box-sizing: border-box;
            align-items: center;
            padding: 8px 2px;

            & > span:first-child{
                height: 100%;
                display: flex;
                flex-direction:column;
                flex-grow:1;
                /* font-family:var(--wardrobeInter); */
                /* font-size:13px;
                /* color:var(--wardrobeColorWhite); */
                /* margin-left:0.75rem;
                justify-content:center;
                font-weight:500;

                & span{
                line-height: 1.25rem;
                font-weight: 500;
                } */
            /* } */

            /* & button:last-child{
                height: 28px;
                width: 28px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                /* font-size: var(--wardrobeSemiLargeFont); */
                /* color:var(--wardrobeColorWhite); */
                /* margin-right: 0.375rem;
                border: 0;
                background-color: transparent;
                cursor: pointer; */
            /* }
            }
        } */
         
    /* } */

/* }  */

.generalInfoModalMain{
    background-color: var(--marketplaceColor);
    color:var(--matterColorLight);
    max-width: 305px;
    height: unset;
    padding: 2px 0px;
    top:calc(var(--topbarHeightDesktop) + 55px);
    left:200%;
    border-radius: 4px;
    transition: left ease-out 0.35s;
    position: fixed !important;
    z-index: 1;
    box-shadow: 0px 7px 14px #00000052;
    height:60px;
    & .modalScrollLayer{
        width: 100%;
        height:100%;
    }
    
    & .modalContainer{
        /* width: 100%;
        height: 100%; */
        display: flex;
        flex-direction: column;
        
        & > button:first-child{display: none}
    
        & .modalContent{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin:auto;
            & .content{
                width: 100%;
                display: flex;
            flex-direction: row;
            box-sizing: border-box;
            align-items: center;
            padding: 8px 2px;
    
            & > span:first-child{
                height: 100%;
                display: flex;
                flex-direction:column;
                flex-grow:1;
                font-family:var(--wardrobeInter);
                font-size:13px;
                color:var(--wardrobeColorWhite);
                margin-left:0.75rem;
                justify-content:center;
                font-weight:500;
    
                & span{
                line-height: 1.25rem;
                font-weight: 500;
                }
            }
    
            & button:last-child{
                height: 28px;
                width: 28px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: var(--wardrobeSemiLargeFont);
                color:var(--wardrobeColorWhite);
                margin-right: 0.375rem;
                border: 0;
                background-color: transparent;
                cursor: pointer;
            }
            }
        }
        
    }
}
.showGeneralInfo{
    left:calc(100% - 310px) !important;
}
.incorrectInfoModalMain{
    background-color: var(--failColor) !important;
}
.incorrectInfoModalMain{
    background-color: var(--failColor) !important;
}
