@import '../../styles/customMediaQueries.css';

.root {
  /* flex-shrink: 0; */
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  height: auto;
}
.topDiv {
  display: flex !important;
  align-items: center;
  margin-top: 15px;
  width: 100%;
}

.content {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.linkDiv {
  width: 100%;
  /* @media (max-height: 360px) {
    overflow-y: scroll;
    max-height: 50px;
    margin-right: 15px;
    padding-right: 10px;
  } */
}
.sectionOne {
  background: #e9f3ff;
}
.footer {
  border-top: 1px solid #e7e7e7;
  padding-left: 11px;
  line-height: 31px;
  padding: 10px 0 10px 11px;
}

.avatar {
  /* Layout */
  /* width: 30%; */
  height: auto;
  flex-shrink: 0;
  margin: var(--TopbarMobileM/enu_topMargin) 0 0 0;
  display: block;
  /* border: 2px solid #ffffff; */
  & img {
    width: 65px;
    height: 65px;
  }
}

.greeting {
  /* Font */
  composes: h1 from global;
  margin: 16px 20px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  /* for ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pageLinks {
  padding: 10px 0;
  width: 100%;
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  /* margin: 4px 0 6px 0; */
  padding: 0;

  /* &:hover {
    text-decoration: underline;
    cursor: pointer;
  } */

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.inbox {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}
.hDivider {
  display: block;
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
  /* margin-top: 40px; */
}
.navigationLink {
  /* Font */
  composes: h2 from global;
  color: var(--matterColorText);
  /* min-height: 49px; */
  width: 100%;
  display: flex;
  align-items: center;
  /* Layout */
  /* margin: 0; */
  margin: 10px 0 0 0;
  font-size: 14px;
  font-weight: 300;
  padding-left: 11px;
  position: relative;

  line-height: 31px;
}
.currentPage {
  color: var(--matterColorLight);
  text-decoration: none;
  background-color: var(--marketplaceColor);
  border-radius: 5px;
}
.currentPageLink {
  /* color: var(--marketplaceColorDark); */
}

.notificationBadge {
  position: absolute;
  /* top: 4px;
  right: -26px; */
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}
.cLL {
  background: #e9f3ff;
}
.createNewListingLink {
  /* composes: button buttonFont buttonText marketplaceButtonBorders buttonColors from global; */
  border: 2px solid var(--marketplaceColor);
  width: 100%;
  margin: 20px 0;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: black;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;
  margin-bottom: 24px;
  /* margin-top: var(--TopbarMobileMenu_topMargin); */
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: var(--marketplaceColor);
  & svg {
    @media (max-width: 768px) {
      height: 60px;
      width: 60px;
    }
  }
}
.authenticationLinks {
  white-space: nowrap;
  margin-left: 15px;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.customUserNav {
  /* padding: 0 36px; */
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e7e7e7;
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 0;
}
.customUserNav button {
  color: var(--matterColorText);
  /* min-height: 49px; */
  width: 100%;
  display: flex;
  align-items: center;
  /* Layout */
  border: none;
  margin: 10px 0 0 0;
  font-size: 14px;
  border: none;
  font-weight: 500;
  padding-left: 11px;
  line-height: 31px;
}
.arrow {
  fill: none;
  width: 10px;
  height: 5px;
  margin-left: 10px;
  font-weight: 700;
  & path {
    stroke: #ffffff;
  }
}
.noOption + div {
  display: none;
}
.optionDiv + div {
  & ul {
    border-radius: 5px;
    padding: 0;
    & li {
      display: flex;
      justify-content: flex-start;
      margin: 5px 5px;
      min-height: 40px;
      align-items: center;
      border-radius: 5px;
      color: var(--matterColorText);
      transition: var(--transitionStyleButton);

      &:hover,
      &:focus {
        color: var(--matterColorLight);
        text-decoration: none;
        background-color: var(--marketplaceColor) !important;
      }
    }
  }
}
