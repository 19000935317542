@import '../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 15px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  font-size: 25px;
  margin-bottom: 15px;
  @media (min-width: 320px) and (max-width: 767.98px) {
    font-size: 18px;
  }
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
.textInput {
  flex-shrink: 0;
  margin: 16px 0px 16px;
  position: relative;
  & input {
    min-height: 54px !important;
  }

  & input,
  & textarea {
    border-bottom: 0px;
    border-radius: 10px;
    /* resize: auto; */
    resize: vertical !important;
    min-height: 77px;

    max-height: 350px;
    border: 1px solid var(--matterColorText);
    /* border: 1px solid var(--marketplaceColor); */
    padding: 20px 18px;
    box-sizing: border-box;
    /* border-radius: var(--borderRadius8); */
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColorBlack);
    @media (min-width: 320px) and (max-width: 575.98px) {
      border-radius: 15px;
      max-height: 146px;
      height: 77px;
      font-size: 14px;
    }
  }
  & label {
    font-weight: 600 !important;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 16px;
    padding: 0px 4px;
    color: #b5b5b5;
    font-size: 14px !important;
  }

  & > div:last-child {
    font-weight: 400 !important;
    margin-left: 4px;
  }
}
.invalidInputs {
  /* background-color: #de4c3980; */

  & label {
    color: var(--failColor) !important;
  }
  & input {
    border-color: var(--failColor) !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
  & textarea {
    border-color: #a51a1523 !important;
    color: var(--matterColorText) !important;

    background-color: #ffc6c423;
  }
}
