@import '../../styles/customMediaQueries.css';
.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;

  @media (min-width: 320px) and (max-width: 575.98px) {
    padding: 11px 15px 0 15px;
  }
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.priceCurrencyInvalid {
  color: var(--failColor);
}
.loginTitle {
  /* text-align: center; */
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  @media (max-width: 575.98px) {
    margin: 10px 0;
    text-align: center;
  }
}
.titleBlue,
.titleBlack {
  font-size: 25px;
  font-weight: 400;
  @media (min-width: 320px) and (max-width: 575.98px) {
    font-size: 20px;
  }
}
.titleBlue {
  color: var(--marketplaceColorDark);
}
.titleBlack {
  color: var(--matterColorText);
}
.hDivider {
  border-bottom: 1px solid var(--matterColorDivider);
  margin-top: 10px;
  @media (max-width: 575.98px) {
    display: none;
  }
}
.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorText);
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 575.98px) {
    text-align: center;
    font-size: 14px;
    margin: 0 0 10px 0;
  }
}
.processingFee {
  color: var(--marketplaceColorDark);
  cursor: pointer;
}
