@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 0 24px;
  @media (min-width: 320px) and (max-width: 575.98px) {
    padding: 11px 15px 80px 15px;
  }
}

.sectionHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-start;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-direction: column-reverse;
  }
}

.title {
  margin-bottom: 0px;
}

.titleDiv {
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  /* color: var(--matterColorAnti); */
  font-size: 25px;
  color: var(--matterColorText);
  font-weight: 500;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 3px 0 3px 0;
  @media (max-width: 575.98px) {
    font-size: 20px;
    margin-bottom: 0;
  }
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorText);
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 575.98px) {
    text-align: left;

    font-size: 16px;
    margin: 10px 0 0px 0;
  }
}
.editPlanButton {
  composes: h5 from global;
  margin: 0;
  padding-top: 5px;
  color: var(--matterColorText);
  font-size: 18px;
  font-weight: 500;
  margin-left: auto;
  & span {
    @media (min-width: 320px) and (max-width: 575.98px) {
      display: none;
    }
  }
  @media (min-width: 320px) and (max-width: 575.98px) {
    padding-right: 20px;
  }
}

.editPlanIcon {
  display: inline-block;
  margin: 0px 3px 4px 0;
  fill: none;
  @media (min-width: 320px) and (max-width: 575.98px) {
    /* width: 15px; */
  }
}

.week {
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--matterColorNegative);
  border-right: solid 1px var(--matterColorNegative);
  border-left: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  margin-bottom: 23px;

  & > .weekDay {
    border-bottom: solid 1px var(--matterColorNegative);
  }
}

.weekDay {
  composes: marketplaceTabNavHorizontalFontStyles from global;
  display: flex;
  flex-direction: row;
  padding: 24px 24px 23px 24px;
}

.dayOfWeek {
  width: 100px;
}

.blockedWeekDay {
  background-color: #f3f3f3;
  & > .dayOfWeek {
    color: var(--matterColorAnti);
  }

  &:hover > .dayOfWeek {
    color: var(--matterColor);
  }
}

.entries {
}

.entry {
  display: block;
}

.exceptionsLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noExceptions {
  composes: h4 from global;
  display: flex;
  align-items: center;
  margin: 0 0 24px 0;
  font-weight: 400;
  font-size: 16px;
}

.exceptions {
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--matterColorNegative);
  border-right: solid 1px var(--matterColorNegative);
  border-left: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  margin-top: 0;
  margin-bottom: 11px;

  & > .exception {
    border-bottom: solid 1px var(--matterColorNegative);
  }
}

.exceptionHeader {
  display: flex;
  flex-direction: row;
}

.exceptionAvailability {
  padding-right: 12px;
  margin: 12px auto 0px 24px;
  display: flex;
  flex-direction: row;
  margin-right: auto;
}

.exceptionAvailabilityDot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 12px;
  background-color: var(--failColor);
}

.isAvailable {
  background-color: var(--successColor);
}

.exceptionAvailabilityStatus {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  padding: 0;
}

.timeRange {
  composes: h5 from global;
  margin: -2px 12px 13px 32px;
  color: var(--matterColorAnti);
}

.removeIcon {
  width: 10px;
  height: 10px;
  stroke: var(--matterColorAnti);
  fill: var(--matterColorAnti);
}

.removeExceptionButton {
  padding: 0 24px 0 24px;
  border: 0;
  cursor: pointer;

  &:hover .removeIcon {
    stroke: var(--matterColorDark);
    fill: var(--matterColorDark);
  }
}

.addExceptionButton {
  composes: h4 from global;
  padding: 1px 0 5px 0;
  margin: 0 0 24px 0;
}

.goToNextTabButton {
  margin-top: auto;
  flex-shrink: 0;
  flex-basis: 30%;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}

.modalContainer {
  composes: marketplaceModalBaseStyles from global;
  min-height: 100vh;
  height: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  background-color: var(--matterColorLight);

  & > button {
    position: absolute;
    margin-left: auto;
  }
}

.error {
  color: var(--failColor);
}

@media (--viewportMedium) {
  .root {
    padding: 11px 24px 0 24px;
  }

  .sectionHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: 10px;
  }

  .title {
    /* margin-bottom: 16px; */
  }

  .weekDay {
    padding-top: 22px;
    padding-bottom: 25px;
    padding-right: 24px;
    padding-left: 24px;
    cursor: pointer;

    &:hover {
      color: var(--matterColorDark);
    }
  }

  .dayOfWeek {
    width: 200px;
  }

  .exceptions {
    margin-bottom: 8px;
  }

  .exceptionAvailability {
    margin: 8px auto 0px 24px;
  }

  .exceptionAvailabilityDot {
    margin-top: 12px;
  }

  .exceptionAvailabilityStatus {
    margin: 0px;
    padding: 5px 0 3px 0;
  }

  .timeRange {
    padding: 6px 0 2px 0;
    margin: -8px 44px 15px 44px;
  }

  .removeIcon {
    width: 12px;
    height: 12px;
  }

  .removeExceptionButton {
    padding: 6px 24px 0 24px;
  }

  .addExceptionButton {
    padding: 3px 0 5px 0;
    margin: 0 0 72px 0;
  }

  .modalContainer {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
  }
}

@media (--viewportLarge) {
  .title {
    /* margin-bottom: 38px; */
    padding: 1px 0 7px 0;
  }

  .goToNextTabButton {
    display: inline-block;
    width: 241px;
    margin-top: 62px;
  }
}
.loginTitle {
  /* text-align: center; */
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  @media (max-width: 575.98px) {
    margin: 10px 0;
    text-align: center;
  }
}
.titleBlue,
.titleBlack {
  font-size: 25px;
  font-weight: 400;
}
.titleBlue {
  color: var(--marketplaceColorDark);
}
.titleBlack {
  color: var(--matterColorText);
}

.form {
  flex-grow: 1;
}

.cancelButton {
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
  flex-basis: 30%;
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 575.98px) {
    flex-basis: 50%;
    margin: 0;
  }
}
.hDivider {
  border-bottom: 1px solid var(--matterColorDivider);
  margin-top: 10px;
  @media (max-width: 575.98px) {
    display: none;
  }
}
.lowerDiv {
  display: flex;
  align-items: center;
  margin: 50px 0;

  /* flex-direction: column; */
  @media (min-width: 320px) and (max-width: 575.98px) {
    display: flex;
    position: fixed;
    width: 100%;
    margin: 20px 0 0;
    bottom: 0;
    left: 0;
    background: white;
    padding: 16px;
    box-shadow: 0px -2px 7px rgb(0 0 0 / 25%);
    flex-direction: row-reverse;
    z-index: 9;
  }
}
