@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;

  /* Clearfix */
  composes: clearfix from global;
}

.messageItem {
  margin-bottom: 20px;

  /* Clearfix */
  composes: clearfix from global;

  @media (--viewportMedium) {
    margin-bottom: 17px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  /* max-width: 433px; */
}

.message {
  display: flex;
  flex-direction: row;

  /* & > div:nth-child(1) {
    & img {
      width: auto;
      height: auto;
    }
  } */
}

.ownMessage {
  float: right;
  display: flex;
  flex-direction: column;
  &>div:nth-child{
    & img{
      margin-right:0 !important
    }
  }
}

.ownContent{
  display: flex;
  flex-direction: row-reverse;
  gap:10px
}

.avatar {
  flex-shrink: 0;
  margin: 0px 12px 0 0;
  width: 35px !important;
  height:35px !important;

  @media (--viewportMedium) {
    margin: 0px 12px 0 0;
  }
}

.ownAvatar {
  flex-shrink: 0;
  margin: 0 0 0 0;
  width: 35px !important;
  height:35px !important;
  @media (--viewportMedium) {
    margin: 0px 12px 0 0;
  }
}

.ownMessageContentWrapper {
  display: flex;
  justify-content: flex-end;
  composes: clearfix from global;
  & img{
    height: 100px;
    width: 100px;
    border-radius: 10px;
  }
}
.ImageWrap{
  composes: clearfix from global;
  & img{
    height: 100px;
    width: 100px;
    border-radius: 10px;
  }
}

.messageContent,
.ownMessageContent {
  composes: marketplaceMessageFontStyles from global;

  display: inline-block;
  margin: 0;
  padding: 8.5px 14px 8.5px 14px;
  border-radius: 8px;
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 8.5px 14px 8.5px 14px;
    margin: 0;
  }
}
.messageText,
.messageContent {
  /* flex: 1; */
  max-width: calc(100vw - 50%);

  word-break: break-word;
}
.ownMessageText{
  float: right;
  display:flex;
  justify-content: flex-end;
  max-width: calc(100vw - 30%);

  word-break: break-word;
}
.ownMessageContent {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  float: right;
  display:flex;
  justify-content: flex-end;
  max-width: calc(100vw - 30%);

  word-break: break-word;
}

.messageDate,
.ownMessageDate {
  composes: marketplaceMessageDateFontStyles from global;

  margin: 11px 0 0 0;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 6px 0 0 0;
  }
}

.ownMessageDate {
  text-align: right;
  margin-right: 62px;
}

.transitionItem {
  margin-bottom: 18px;

  /* Clearfix */
  composes: clearfix from global;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: row;
  padding: 3px 0 2px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.transitionContent {
  composes: marketplaceTxTransitionFontStyles from global;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.transitionDate {
  composes: marketplaceMessageDateFontStyles from global;
  color: var(--matterColorAnti);
  margin: 7px 0 0 0;
  font-size: 13px;
  font-weight: 400;
  @media (--viewportMedium) {
    margin: -1px 0 1px 0;
  }
}

.bullet {
  margin-right: 6px;
}

.reviewContent {
  composes: h4 from global;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;
  word-break: break-word;

  @media (--viewportMedium) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStatus {
  margin-top: 4px;
  & .statusHeading {
    color: var(--matterColorAnti);
  }
  & .base {
    text-transform: capitalize;
    color: var(--matterColorAnti);
  }

  & .pending {
    color: var(--matterColor);
  }

  & .accepted {
    color: var(--marketplaceColor);
  }

  & .declined {
    color: var(--failColor);
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin: 5px 0;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.messageWrapper {
  margin-top: 24px;
  padding-bottom: 16px;

  & .divider {
    margin-bottom: 24px;
  }
}

.showOlderButton {
  margin-bottom: 16px;
}

.previewImgWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;

  & img {
    width: 96px;
    height: 96px;
    /* object-fit: contain; */
    object-fit: cover;
    margin-right: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
    background-color: #e9e8e8;
    @media (max-width: 320px) {
      width: 75px;
      height: 75px;
    }
  }
}

.carouselModalScrollLayer{
  position: fixed; 
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(32, 31, 31, 0.92);
}
.carouselModalContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &>div{
    width: 1000px;
    height: 500px;
    &>div{
      &>div{
        &>div{
          &>div{
            &>div{
              &>div{
                & img{
                  background-color:transparent !important;
                }
                &>div{
                  & img{
                    margin-right: 50px !important;
                    background-color:transparent !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
}
.showImageButtonDiv{
  display: flex;
  /* width: 100%; */
  justify-content: flex-end;
}
.showImageButton{
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  border: 2px solid black;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  width: fit-content;
}
/* .wrapperClassName {
  
} */
.arrowBtn {
  fill: none;
  & path {
    stroke: #ffffff;
  }
}
.singleImage{
  /* margin-right: 8px;
  margin-bottom: 8px; */
  /* position: relative; */
  /* width: 168px; */
  /* height: 168px; */
  width: 100px;
  height: 100px;
  aspect-ratio: 1;
  cursor: pointer;
}
.ownImageDiv{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0;
  gap:8px;
}
.imageDiv{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0;
  gap:8px;
  /* padding: 8.5px 14px 8.5px 14px;
  border-radius: 8px;
  box-shadow: var(--boxShadow);
  gap:8px;
  @media (--viewportMedium) {
    padding: 8.5px 14px 8.5px 14px;
    margin: 0;
  }
  max-width: calc(100vw - 50%);

  word-break: break-word; */
}
.messageDiv{
  padding: 8.5px 14px 8.5px 14px;
  border-radius: 8px;
  box-shadow: var(--boxShadow);
  gap:8px;
  @media (--viewportMedium) {
    padding: 8.5px 14px 8.5px 14px;
    margin: 0;
  }
  max-width: calc(100vw - 50%);

  word-break: break-word;
}
.ownMessageText,
.messageText{
  composes: marketplaceMessageFontStyles from global;
  margin-top:5px;
}